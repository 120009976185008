<template>
    <div id="preloader" v-if="loading">
        <div class="spinner-border color-highlight" role="status"></div>
    </div>
    <component :is="offerComponent" :options="{
        type: 'loop', autoplay: true, interval: 4000, perPage: 1, autoWidth: true, pauseOnHover: false,
        pauseOnFocus: false
    }">
        <SplideSlide v-for="(offer, index) in future" :key="index">
            <router-link :to="`/benefits/${offer.id}`" class="card me-0 card-style"
                :style="{ backgroundImage: `url(${offer.banner})`, height: '300px', width: '330px' }"
                data-card-height="300">
                <div class="card-top">
                    <span class="badge float-end bg-green-dark p-2 text-uppercase rounded-s m-2">REDEEM
                        NOW</span>
                </div>
                <div class="card-bottom m-3">
                    <h1 class="color-white font-30">
                        {{ offer.title }}
                    </h1>
                    <p class="mb-0 color-white font-18 opacity-70 pt-1">{{ offer.subtitle }}</p>
                </div>

                <div class="card-overlay bg-gradient opacity-90"></div>
            </router-link>
        </SplideSlide>
    </component>

    <div class="card card-style mb-2" v-for="(offer, index) in benefits" :key="index">
        <router-link :to="`/benefits/${offer.id}`" class="content ms-0 my-0">
            <div class="d-flex">
                <div class="align-self-center me-auto">
                    <img :src="offer.banner" class="me-3" width="90">
                    <span v-if="new Date(offer.end_date.seconds * 1000) >= new Date(Date.now())"
                        class="badge text-uppercase px-2 py-1 bg-green-dark d-block me-3 mt-n3 under-slider-btn">Available</span>
                    <span v-else
                        class="badge text-uppercase px-2 py-1 bg-red-dark d-block me-3 mt-n3 under-slider-btn">Expired</span>
                </div>
                <div class="align-self-center w-100">
                    <h5 class="font-15 pt-2">{{ offer.title }}</h5>
                    <p class="font-10 my-0 py-0">{{ offer.subtitle }}</p>
                    <span class="color-theme font-11 opacity-50">
                        <i class="far fa-clock fa-fw pe-2"></i>Expired At : {{ offer.end_date_formated }}
                    </span>
                </div>
            </div>
        </router-link>
    </div>
</template>
<script>
import { doc, getDoc } from "firebase/firestore";
import { db } from "@/firebase.js";
import moment from "moment"
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css/core';

export default {
  name: 'BenefitView',
  components: {
    Splide,
    SplideSlide,
  },
  data () {
    return {
      loading: true,
      benefits: [],
      future: [],
      id: this.$route.params.id,
      showError: false
    };
  },
  async created () {

        this.benefits = await this.readDataBenefit()
        this.future = this.readDataBenefitFuture()
      this.offerComponent = 'Splide',
        this.loading = false;
    },
  methods: {
        async readDataBenefit () {
            const benefit = await getDoc(
                doc(db, "myalumni", "benefits")
            );

            if (benefit.exists()) {
                let tempBenefit = benefit.data().offers
                tempBenefit = tempBenefit.filter((item) => {
                    return item.end_date_formated = moment(item.end_date.seconds * 1000, 'x').format('DD MMMM YYYY')
                })
                
                return tempBenefit;

                //return benefit.data()
            } else {
                alert('Fail to load data')
            }
        },
        readDataBenefitFuture () {
                let tempBenefit = this.benefits

                tempBenefit = tempBenefit.filter((item) => {
                    return (item.feature == true)
                })

                return tempBenefit;
        }
    },
}
</script>
