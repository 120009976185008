<template>

  <div id="preloader" v-if="loading">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>
  <component :is="offerComponent" :options="{
    type: 'loop', autoplay: true, interval: 4000, perPage: 1, autoWidth: true, pauseOnHover: false,
    pauseOnFocus: false
  }">

    <SplideSlide v-for="(offer, index) in offers" :key="index">
      <router-link :to="`/benefits/${offer.id}`" class="card me-0 card-style"
        :style="{ backgroundImage: `url(${offer.banner})`, height: '300px', width: '330px' }" data-card-height="300">
        <div class="card-top">
          <span class="badge float-end bg-green-dark p-2 text-uppercase rounded-s m-2">REDEEM
            NOW</span>
        </div>
        <div class="card-bottom m-3">
          <h1 class="color-white font-30">
            {{ offer.title }}
          </h1>
          <p class="mb-0 color-white font-18 opacity-70 pt-1">{{ offer.subtitle }}</p>
        </div>

        <div class="card-overlay bg-gradient opacity-90"></div>
      </router-link>
    </SplideSlide>
  </component>
  <div class="row mb-0" v-if="open">
    <div class="col-12 pe-2">
      <div class="card card-style mb-2" data-card-height="200"
        :style="{ 'height': '200px', 'background-image': 'url('+setting.banner+')'}">
        <div class="card-center">
          <h1 class="color-white text-center mb-0 mx-2">{{setting.tajuk}}
          </h1>
          <p class="color-white text-center opacity-60 mt-n1 m-3">Get your latest update about graduation day here</p>
          <router-link to="/convo" class="btn btn-m font-900 text-uppercase bg-red-dark btn-center-xl">VISIT
            NOW</router-link>
        </div>
        <div class="card-overlay bg-black opacity-70"></div>
      </div>
    </div>
  </div>
  <div class="row mb-0">
    <div class="col-6 pe-0">
      <router-link to="/business" class="card card-style bg-20 mb-2" data-card-height="135" style="height: 135px;">
        <div class="card-bottom p-3">
          <h5 class="color-white font-500 font-18 mb-n1">
            UiTM Alumni Business Listing
          </h5>
        </div>
        <div class="card-overlay bg-black opacity-70"></div>
      </router-link>
      <a href="https://myfuturejobs.gov.my/" target="_blank" class="card card-style mb-2" data-card-height="135"
        style="background-image: url(/images/pictures/30.jpg); height: 135px;">
        <div class="card-bottom p-3">
          <h5 class="color-white font-500 font-18 mb-n1">
            MyFutureJobs
          </h5>
          <span class="color-white font-14">Perkeso</span>
        </div>
        <div class="card-overlay bg-black opacity-70"></div>
      </a>
    </div>
    <div class="col-6 ps-0">
      <a href="https://jobshop.uitm.edu.my/" target="_blank" class="card card-style ms-0 " data-card-height="280"
        style="background-image: url(/images/pictures/32.jpg); height: 280px;">
        <div class="card-bottom p-3">
          <h5 class="color-white font-20">
            Job Shop UiTM
          </h5>
        </div>
        <div class="card-overlay bg-black opacity-70"></div>
      </a>
    </div>
  </div>
  <div class="divider divider-margins mb-4"></div>
  <div class="content mb-4">
    <h4>{{ giving_title }}</h4>
    <p>{{ giving_subtitle }}</p>
  </div>
    <component :is="givingComponent" :options="{
    type: 'loop', autoWidth: true, autoplay: true, interval: 4000, arrows: false, perPage: 2
  }">
      <SplideSlide v-for="(link, index) in givings" :key="index">
        <a :href="link.url" target="_blank">
          <div style="height: 170px; width: 125px;" class="card ms-3 rounded-m shadow-l">
            <div class="card-top">
              <span class="icon icon-m rounded-circle ms-3 mt-3">
                <div v-html="link.icon"></div>
              </span>
            </div>
            <div class="card-bottom">
              <h4 class="color-theme font-15 mb-3 ms-3"><span v-html="link.title"></span></h4>
            </div>
            <div class="card-overlay bg-theme"></div>
          </div>
        </a>
      </SplideSlide>
    </component>
  
  <div id="notification-1" @click="notification='hide'"
    class="toast w-auto notification notification-ios bg-dark-dark notch-push mt-3 fade" :class="notification">
    <span @click="notification='hide'" class="notification-icon">
      <i class="fa fa-bell"></i>
      <em>Notification</em>
      <i class="fa fa-times-circle"></i>
    </span>
    <h1 class="font-15 color-white mb-n3">{{ message.title }}</h1>
    <p class="pt-2">
      {{ message.body }}
    </p>
  </div>
</template>
<script>
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db, messaging } from '@/firebase.js';
import { getToken } from 'firebase/messaging';
import { onMessage } from 'firebase/messaging';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import { useAuthStore } from '@/stores'
import '@splidejs/splide/dist/css/splide.min.css';

export default {
  name: 'HomeView',
  components: {
    Splide, SplideSlide
  },
  data () {
    return {
      open: false,
      setting: {},
      notification: 'hide',
      message: {},
      loading: true,
      offers: false,
      givings: false,
      convo: false,
      giving_title: "",
      giving_subtitle: "",
      id: this.$route.params.id,
      showError: false,
      offerComponent: '',
      givingComponent: ''
    };
  },
  setup () {
    const authStore = useAuthStore()
    const user = authStore.user

    return {
      user
    }

  },
  async created () {
    this.convo = await this.readUser(this.user.alumni_id)
    this.setting = await this.readSetting()
    this.offers = await this.readData()
    this.givings = await this.readDataGiving()
    this.offerComponent= 'Splide',
    this.givingComponent= 'Splide'
    this.loading = false;

    if (this.convo && new Date(this.setting.end_date.seconds * 1000) >= new Date(Date.now()) && new Date(this.setting.start_date.seconds * 1000) <= new Date(Date.now())) {
      this.open = true
    } else {
      this.open = false
    }

    getToken(messaging, { vapidKey: 'BPEHyI2iHuqJ4cyoSGmu-Z9avN4CVWoSWYacjY1xnbWxAQEmn9VVZ2I1lLxiiXNG_ZDg7uAROMpaocZr_nqZtvg' }).then(async (currentToken) => {
      if (currentToken) {
        console.log('FCM Token:', currentToken);
        // Send the token to your server and update the UI if necessary
        await setDoc(doc(db, 'alumni', `${this.user.alumni_id}`), { 'fcm_token': currentToken }, { merge: true })
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });

  },
  methods: {
    async readUser (param) {
      
      const data = await getDoc(
        doc(db, "graduates", `${param}`)
      );

      if (data.exists()) {
        return data.data()
      }
    },
    async readSetting() {

      const data = await getDoc(
        doc(db, "myalumni", "convocation")
      );

      if (data.exists()) {
        
        
        return data.data()
      }
    },
    async readData () {
      const benefits = await getDoc(
        doc(db, "myalumni", "benefits")
      );

      if (benefits.exists()) {

        let tempBenefit = benefits.data().offers

        tempBenefit = tempBenefit.filter((item) => {
          if (new Date(item.end_date.seconds * 1000) >= new Date(Date.now()) && item.feature == true) {
            return true
          } else {
            return false
          }
        })

        return tempBenefit;
      } else {
        alert('Fail to load data')
      }
    },
    async readDataGiving () {
      const givings = await getDoc(
        doc(db, "myalumni", "giving")
      );

      if (givings.exists()) {
        this.giving_title = givings.data().title
        this.giving_subtitle = givings.data().subtitle
        let tempGiving = givings.data().links

        tempGiving = tempGiving.filter((item) => {
          return (item.status == true)
        })

        return tempGiving;

        //return giving.data()
      } else {
        alert('Fail to load data')
      }
    }
  },
  mounted: function () {
    onMessage(messaging, (payload) => {
      this.message = payload.notification
      this.notification = 'show'
    });

  }


}
</script>