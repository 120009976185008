<template>
    <div id="preloader" v-if="loading">
        <div class="spinner-border color-highlight" role="status"></div>
    </div>
    <div data-card-height="cover" :style="{height : coverHeight}" class="card">
        <div class="card-center">
            <div class="ps-5 pe-5">
                <h1 class="color-white text-center font-800 font-40 mb-1"><img src="/images/alumni.png"
                        style="width: 50% !important; max-width: 200px;"></h1>
                <p class="color-white color-highlight text-center font-12"><img src="/images/preload-logo.png"
                        style="width: 50% !important; max-width: 200px;"></p>
                <a href="#" @click="redirectSignin('google')"
                    class="btn btn-icon btn-m btn-full shadow-l rounded-sm bg-google text-uppercase font-900 text-start"><i
                        class="bg-transparent fab fa-google text-center"></i>Login with Google</a>
                <!-- <a @click="redirectSignin('facebook')" href="#"
                    class="btn btn-icon btn-m btn-full shadow-l rounded-sm bg-facebook text-uppercase font-900 text-start mt-2"><i
                        class="bg-transparent fab fa-facebook-f text-center"></i>Login with Facebook</a>
                <a @click="redirectSignin('twitter')" href="#"
                    class="btn btn-icon btn-m btn-full shadow-l rounded-sm bg-twitter text-uppercase font-900 text-start mt-2 "><i
                        class="bg-transparent fab fa-twitter text-center"></i>Login with Twitter</a> -->
                <a @click="redirectSignin('microsoft')" href="#"
                    class="btn btn-icon btn-m btn-full shadow-l rounded-sm bg-microsoft text-uppercase font-900 text-start mt-2"><i
                        class="bg-transparent fab fa-microsoft text-center"></i>Login with Microsoft</a>
            </div>

        </div>
        <div class="card-overlay bg-white opacity-80"></div>
        <div class="card-overlay-infinite preload-img" style="background-image: url('/images/pictures/18w.jpg');"></div>
    </div>
</template>

<script>
import { auth,db } from '@/firebase.js';
import { GoogleAuthProvider, OAuthProvider, TwitterAuthProvider, FacebookAuthProvider, signInWithRedirect, signInWithPopup, inMemoryPersistence, setPersistence } from "firebase/auth";
import { useAlertStore,useAuthStore } from '@/stores'
import { query, collection, where, getDocs } from "firebase/firestore";
//import Crypto from 'crypto-js';
import router from "@/router";

export default {
    data () {
        return {
            coverHeight: 0,
            windowHeigh: 0,
            loading: true,
        }
    },
    async created () {
        if (window.matchMedia('(display-mode: fullscreen)').matches) { this.windowHeight = window.outerHeight; }
        if (!window.matchMedia('(display-mode: fullscreen)').matches) { this.windowHeight = window.innerHeight; }
        //Fix for iOS 15 pages with data-height="cover"
        this.coverHeight = this.windowHeight + 'px';

        const authStore = useAuthStore()
        const result = await authStore.getCurrentUser();
        if (result){
            result.type = result.providerData[0].providerId.substring(0, result.providerData[0].providerId.lastIndexOf("."))
            let users = []
            let data = {}
            let q = {}

            try {
                q = query(collection(db, 'alumni'), where(result.type, '==', result.email))
                const querySnap = await getDocs(q);

                querySnap.forEach((doc) => {
                    users.push(doc.data())
                })
                if (users.length > 0) {
                    data = users[0]
                    //data.alumni_id = Crypto.AES.encrypt(users[0].alumni_id.toString(), 'p@ssw0rd').toString()
                    authStore.login(data)

                } else {
                    router.push("/register");
                }
            } catch (error) {
                const alertStore = useAlertStore()
                alertStore.error(error)
            }
        }
        this.loading = false
    },
    methods: {
        async popupSignin (type) {
            this.loading = true
            const authStore = useAuthStore()
            try {
                var provider = '';
                if(type == 'google')
                    provider = new GoogleAuthProvider();
                else if (type == 'facebook')
                    provider = new FacebookAuthProvider;
                else if (type == 'twitter')
                    provider = new TwitterAuthProvider();
                else if (type == 'microsoft')
                    provider = new OAuthProvider('microsoft.com');

                await signInWithPopup(auth, provider)
                    .then(async (result) => {


                        result.type = result.providerId.substring(0, result.providerId.lastIndexOf("."))
                        let users = []
                        let data = {}
                        let q = {}

                        try {
                            q = query(collection(db, 'alumni'), where(result.type, '==', result.user.email))
                            const querySnap = await getDocs(q);

                            querySnap.forEach((doc) => {
                                users.push(doc.data())
                            })
                            if (users.length > 0) {
                                data = users[0]
                                //data.alumni_id = Crypto.AES.encrypt(users[0].alumni_id.toString(), 'p@ssw0rd').toString()
                                authStore.login(data)

                            } else {
                                router.push("/register");
                            }
                        } catch (error) {
                            const alertStore = useAlertStore()
                            alertStore.error(error)
                        }
                    })
            } catch (error) {
                const alertStore = useAlertStore()
                alertStore.error((error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message.replace('Firebase:', '') ||
                    error.replace('Firebase:', '').toString())
            }
        },
        redirectSignin (type) {
            this.loading = true
            try {
                var provider = '';
                if (type == 'google')
                    provider = new GoogleAuthProvider();
                else if (type == 'facebook')
                    provider = new FacebookAuthProvider;
                else if (type == 'twitter')
                    provider = new TwitterAuthProvider();
                else if (type == 'microsoft')
                    provider = new OAuthProvider('microsoft.com');
                setPersistence(auth, inMemoryPersistence)
                    .then(() => { return signInWithRedirect(auth, provider)
                    })
            } catch (error) {
                this.popupSignin(type)
            }
            this.loading = false
        }
    }
}
</script>
