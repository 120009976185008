<template>
    <div id="preloader" v-if="loading">
        <div class="spinner-border color-highlight" role="status"></div>
    </div>
    <img style="margin-bottom:-80px" :src="profile.picture ?? 'images/no-picture.png'"
        class="mx-auto shadow-xl rounded-circle over-card" width="150">
    <div style="padding-top:80px;" class="card card-style">
        <div class="content">
            <h1 class="text-center font-32">{{ profile.nama }}</h1>
            <div class="row mb-0">
                <div class="col-12">
                    <span class="font-12">Email</span>
                    <p class="mt-n2 mb-3">
                        <strong class="color-theme">{{ profile.google ?? profile.microsoft ?? profile.facebook ??
                            profile.twitter }}</strong>
                    </p>
                </div>
            </div>
            <div class="row mb-0">
                <div class="col-12">
                    <span class="font-12">Mobile Phone No</span>
                    <p class="mt-n2 mb-3">
                        <strong class="color-theme">{{ profile.phone_no }}</strong>
                    </p>
                </div>
            </div>
            <div class="row mb-0">
                <div class="col-12">
                    <span class="font-12">Address</span>
                    <p class="mt-n2 mb-3">
                        <strong class="color-theme">{{ profile.address }}</strong>
                    </p>
                </div>
            </div>
            <div class="row mb-0">
                <div class="col-12">
                    <span class="font-12">Current Employement Status</span>
                    <p class="mt-n2 mb-3">
                        <strong class="color-theme">{{ profile.status_pekerjaan }}</strong>
                    </p>
                </div>
            </div>
            <div class="row mb-0"
                v-if="profile.status_pekerjaan == 'Employed' || profile.status_pekerjaan == 'Self-employed'">
                <div class="col-12">
                    <span class="font-12">Employement Sector</span>
                    <p class="mt-n2 mb-3">
                        <strong class="color-theme">{{ profile.sektor_pekerjaan }}</strong>
                    </p>
                </div>
            </div>
            <div class="row mb-0"
                v-if="profile.status_pekerjaan == 'Employed' || profile.status_pekerjaan == 'Self-employed'">
                <div class="col-12">
                    <span class="font-12">Occupational Field</span>
                    <p class="mt-n2 mb-3">
                        <strong class="color-theme">{{ profile.bidang_pekerjaaan }}</strong>
                    </p>
                </div>
            </div>
            <div class="row mb-0"
                v-if="profile.status_pekerjaan == 'Employed' || profile.status_pekerjaan == 'Self-employed'">
                <div class="col-12">
                    <span class="font-12">Employment Level</span>
                    <p class="mt-n2 mb-3">
                        <strong class="color-theme">{{ profile.tahap_pekerjaaan }}</strong>
                    </p>
                </div>
            </div>
            <div class="row mb-0"
                v-if="profile.status_pekerjaan == 'Employed' || profile.status_pekerjaan == 'Self-employed'">
                <div class="col-12">
                    <span class="font-12">Range of Salary</span>
                    <p class="mt-n2 mb-3">
                        <strong class="color-theme">{{ profile.gaji }}</strong>
                    </p>
                </div>
            </div>
            <div class="row mb-0" v-if="profile.status_pekerjaan == 'Employed'">
                <div class="col-12">
                    <span class="font-12">Designation/Post</span>
                    <p class="mt-n2 mb-3">
                        <strong class="color-theme">{{ profile.jawatan }}</strong>
                    </p>
                </div>
            </div>
            <div class="row mb-0"
                v-if="profile.status_pekerjaan == 'Employed' || profile.status_pekerjaan == 'Self-employed'">
                <div class="col-12">
                    <span class="font-12">Name of Organisation</span>
                    <p class="mt-n2 mb-3">
                        <strong class="color-theme">{{ profile.organisasi }}</strong>
                    </p>
                </div>
            </div>
            <div class="row mb-0"
                v-if="profile.status_pekerjaan == 'Employed' || profile.status_pekerjaan == 'Self-employed'">
                <div class="col-12">
                    <span class="font-12">Location of Workplace</span>
                    <p class="mt-n2 mb-3">
                        <strong class="color-theme">{{ profile.location }}</strong>
                    </p>
                </div>
            </div>
            <div class="row mb-0" v-if="profile.status_pekerjaan == 'Unemployed'">
                <div class="col-12">
                    <span class="font-12">Fulltime Student</span>
                    <p class="mt-n2 mb-3">
                        <strong class="color-theme">{{ (profile.student ==
                            'true') ? 'Yes' : 'No' }}</strong>
                    </p>
                </div>
            </div>
            <div class="row mb-0" v-if="profile.status_pekerjaan == 'Unemployed' && profile.student == 'true'">
                <div class="col-12">
                    <span class="font-12">University</span>
                    <p class="mt-n2 mb-3">
                        <strong class="color-theme" v-if="profile.university">{{ universities.name }}({{
                            universities.code
                            }})</strong>
                    </p>
                </div>
            </div>
            <div class="row mb-0"
                v-if="profile.status_pekerjaan == 'Unemployed' && profile.student == 'true' && profile.university == '999'">
                <div class="col-12">
                    <span class="font-12">Name of Institution</span>
                    <p class="mt-n2 mb-3">
                        <strong class="color-theme">{{ profile.tempat_pengajian }}</strong>
                    </p>
                </div>
            </div>

            <router-link to="/profile/edit"
                class="btn btn-m rounded-sm btn-full bg-highlight font-700 text-uppercase">Edit Profile</router-link>

            <div class="divider my-3"></div>
            <p class="px-3"><small class="pt-3">DISCLAIMER : The information provided will be handled based on the
                    <a href="#" @click="consent(true)">Personal
                        Data Protection Act 2010 (Act 709)</a> which is applicable and effective to UiTM</small></p>
        </div>
    </div>
    <div class="card card-style">
        <div class="content">
            <h3>History of Studies</h3>
        </div>
    </div>
    <div class="timeline-body mt-0">
        <div class="timeline-deco mt-5"></div>

        <div class="timeline-item" v-for="(sejarah, index) in profile.sejarah_pengajian" :key="index">
            <span class="timeline-icon bg-red-dark font-14">{{ sejarah.tahun_tamat.toString().substring(0, 4)
                }}</span>
            <div class="timeline-item-content rounded-s">
                <h5>{{ sejarah.code_kursus}} - {{sejarah.nama_kursus}}</h5>
                <p class="mb-0">
                    {{ sejarah.kampus }}
                </p>
            </div>
        </div>
    </div>

    <div id="menu-gdpr" class="menu menu-box-bottom menu-box-detached rounded-m" data-menu-effect="menu-over"
        data-menu-height="450" style="display: block;  height: 450px; border: 2px solid green; ">
        <div class="menu-title mt-n1">
            <h1>Personal Data Protection Notice </h1>
            <p class="color-highlight">PERSONAL DATA PROTECTION POLICY</p>
            <a href="#" @click="consent(false)" class="close-menu"><i class="fa fa-times"></i></a>
        </div>
        <div class="content mt-2">
            <div class="divider mb-1"></div>
            <div class="terms">
                <p class="lh-sm text-justify my-2">Personal Data Protection Act 2010 (PDPA) applies to all processes
                    involving personal data in the
                    country.</p>
                <p class="lh-sm text-justify my-2">UNIVERSITI TEKNOLOGI MARA (UiTM) is committed to ensuring that your
                    privacy is protected according to
                    the
                    Personal Data Protection Act 2010.
                </p>
                <p class="lh-sm text-justify my-2">UiTM is required to inform you of your rights regarding your
                    personal data
                    that is being processed, collected, and further processed as well as the purposes of the data
                    processing for
                    administration, education, research, or non-commercial use only.</p>

                <p class="lh-sm text-justify my-2">By submitting this form, I hereby agree that UiTM may collect,
                    obtain, store, and process the
                    Personal Data
                    that
                    UiTM provides in this form for registration processing and administration of UiTM's internal usage.
                </p>

                <p class="lh-sm text-justify my-2">I hereby give consent to UiTM to:-
                <ol type="a" class="lh-sm text-justify my-2">
                    <li>store and process my Personal Data;</li>
                    <li>disclose my Personal Data to UiTM’s internal department</li>
                </ol>
                </p>

                <p class="lh-sm text-justify my-2">In addition, my personal data may be transferred within UiTM only.
                    For the purpose of updating or
                    correcting
                    such data, I at any time apply to UiTM to have access to my personal data which are stored by UiTM.
                </p>
                <p class="lh-sm text-justify my-2">For avoidance of doubt, Personal Data includes all data defined under
                    the Personal Data Protection
                    Act 2010 and
                    includes all data that has been disclosed to UiTM via this form.</p>
            </div>
        </div>
    </div>
</template>
<style>
.terms{
    overflow-y: scroll;
}
.text-justify{
    text-align: justify;
        text-justify: inter-word;
}
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
</style>
<script>
import { doc, getDoc } from "firebase/firestore";
import { db } from '@/firebase.js';
import { useAuthStore } from '@/stores'
export default {
    name: 'ProfileView',
    data () {
        return{
            loading: true,
            profile: {},
            searchResult: {},
            universities: {}
        }
    },
    setup () {
        const authStore = useAuthStore()
        const user = authStore.user
        
        return {
            user
        }

    },
    async created () {
        this.profile = await this.readData(this.user)
        this.universities = await this.readDataUniversity(this.user.university)
        
        this.loading = false

    },
    methods: {
        consent(status){
            if(status == true){
                document.getElementsByClassName('menu')[0].classList.add('menu-active');
                document.getElementsByClassName('menu-hider')[0].classList.add('menu-active');
            }else{
                document.getElementsByClassName('menu')[0].classList.remove('menu-active');
                document.getElementsByClassName('menu-hider')[0].classList.remove('menu-active');
            }
        },
        async readDataUniversity (param) {
            const universities = await getDoc(
                doc(db, "myalumni", "parameters")
            );
            if (universities.exists()) {
                let tempUniversities = universities.data().universities
                
                const foundObject = tempUniversities.find(item => item.id === param);
                return foundObject
            } else {
                alert('Fail to load data')
            }
        },
        async readData (param) {
            const data = await getDoc(
                doc(db, "alumni", `${param.alumni_id}`)
            );

            if (data.exists()) {
                return data.data()
            }
        }
    }
}
</script>