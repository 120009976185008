<template>
    <div id="preloader" v-if="loading">
        <div class="spinner-border color-highlight" role="status"></div>
    </div>
    <div class="card card-style preload-img" style="height: 130px; background-image: url('/images/pictures/18w.jpg');"
        data-card-height="130">
        <div class="card-center ms-3">
            <h1 class="color-white mb-0">Attendance Confirmation</h1>
        </div>
        <div class="card-center me-3">
            <router-link to="/convo"
                class="btn btn-m float-end rounded-xl shadow-xl text-uppercase font-800 bg-highlight">Back
            </router-link>
        </div>
        <div class="card-overlay bg-black opacity-80"></div>
    </div>

    <div class="card card-style">
        <div class="content">
            <form>
                <div class="mb-3">
                    <label for="nama" class="form-label">Name</label>
                    <input type="text" class="form-control-plaintext" id="nama" v-model="this.user.nama" disabled>
                </div>
                <div class="mb-3">
                    <label for="email" class="form-label">Email</label>
                    <input type="text" class="form-control-plaintext" id="email"
                        :value="this.user.google ?? this.user.microsoft ?? this.user.facebook ??  this.user.twitter"
                        disabled>
                </div>
                <div class="mb-3">
                    <label for="phone_no" class="form-label">Mobile Phone No<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" :class="(formErrors.phone_no) ? 'is-invalid':''"
                        id="phone_no" v-model="profile.phone_no">
                    <div v-if="formErrors.phone_no" class="invalid-feedback">
                        {{ formErrors.phone_no }}
                    </div>
                </div>
                <div class="mb-3">
                    <label for="formFile" class="form-label">Upload SKPG Form<span class="text-danger">*</span></label>
                    <input class="form-control" type="file" :class="(formErrors.formFile) ? 'is-invalid' : ''"
                        id="formFile" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps">
                    <div v-if="formErrors.formFile" class="invalid-feedback">
                        {{ formErrors.formFile }}
                    </div>
                </div>
                <div class="mb-3">
                    <label for="bapa_alumni" class="form-label">My father is a UiTM Alumni <span
                            class="text-danger">*</span></label>
                    <select class="form-select" id="bapa_alumni" v-model="profile.bapa_alumni"
                        @change="formsearch($event.target.value,'bapa')"
                        :class="(formErrors.bapa_alumni) ? 'is-invalid' : ''">
                        <option value="" disabled selected>Select a Value</option>
                        <option value=true>Yes</option>
                        <option value=false>No</option>
                    </select>
                    <div v-if="formErrors.bapa_alumni" class="invalid-feedback">
                        {{ formErrors.bapa_alumni }}
                    </div>

                </div>
                <div class="mb-3" v-if="profile.bapa_icno">
                    <label for="icno_view" class="col-form-label">IC/Passport/Student No.</label>
                    <input type="text" class="form-control-plaintext" id="bapa_nama" v-model="profile.bapa_icno"
                        readonly>
                    <label for="nama" class="col-form-label">Name</label>
                    <input type="text" class="form-control-plaintext" id="bapa_nama" v-model="profile.bapa_nama"
                        readonly>
                    <label for="nama" class="col-form-label">Mobile Phone No</label>
                    <input type="text" class="form-control-plaintext" id="bapa_phone_no" v-model="profile.bapa_phone_no"
                        readonly>
                </div>
                <div class="mb-3">
                    <label for="ibu_alumni" class="form-label">My mother is a UiTM Alumni <span
                            class="text-danger">*</span></label>
                    <select class="form-select" id="ibu_alumni" @change="formsearch($event.target.value, 'ibu')"
                        v-model="profile.ibu_alumni" :class="(formErrors.ibu_alumni) ? 'is-invalid' : ''">
                        <option value="" disabled selected>Select a Value</option>
                        <option value=true>Yes</option>
                        <option value=false>No</option>
                    </select>
                    <div v-if="formErrors.ibu_alumni" class="invalid-feedback">
                        {{ formErrors.ibu_alumni }}
                    </div>
                </div>
                <div class="mb-3" v-if="profile.ibu_icno">
                    <label for="icno_view" class="col-form-label">IC/Passport/Student No.</label>
                    <input type="text" class="form-control-plaintext" id="ibu_nama" v-model="profile.ibu_icno" readonly>
                    <label for="nama" class="col-form-label">Name</label>
                    <input type="text" class="form-control-plaintext" id="ibu_nama" v-model="profile.ibu_nama" readonly>
                    <label for="nama" class="col-form-label">Mobile Phone No</label>
                    <input type="text" class="form-control-plaintext" id="ibu_phone_no" v-model="profile.ibu_phone_no"
                        readonly>
                </div>
                <a href="#" @click="submitForm" class="btn btn-m rounded-sm btn-full bg-highlight font-700
                text-uppercase">Save</a>
            </form>
        </div>
    </div>


    <!-- menu age-->
    <div id="verifyPopup" class="menu menu-box-bottom menu-box-detached rounded-m" style="display: block;">
        <div class="menu-title mt-n1">
            <h1 class="text-capitalize ">{{ label }}</h1>
            <p class="color-theme opacity-50">Verfiy your {{ label }} details</p>
            <a href="#" @click="formsearch(false, label)" class="close-menu"><i class="fa fa-times"></i></a>
        </div>
        <div class="content mt-2">
            <div v-if="error" class="alert alert-danger" role="alert">
                <div v-html="message"></div>
            </div>
            <div class="mb-3" v-if="!found">
                <label for="icno" class="form-label">IC/Passport/Student No.<span class="text-danger">*</span></label>
                <input type="text" class="form-control" :class="(formErrors.icno) ? 'is-invalid' : ''" id="icno"
                    v-model="penjaga.icno" maxlength="12" :readonly="found">
                <div v-if="formErrors.icno" class="invalid-feedback">
                    {{ formErrors.icno }}
                </div>
            </div>
            <a @click="verify" v-if="!found" href="#"
                class="mb-3 btn btn-full btn-m shadow-l rounded-s bg-green-dark mt-3 text-uppercase font-900"
                :disabled="searching">
                <span v-if="searching"><i class="fa-solid fa-spinner fa-spin-pulse"></i> Searching...</span><span
                    v-else>Verify</span></a>
            <div v-if="found">
                <label for="icno_view" class="col-form-label">IC/Passport/Student No.</label>
                <input type="text" class="form-control-plaintext" id="nama" v-model="penjaga.icno" readonly>
                <label for="nama" class="col-form-label">Name</label>
                <input type="text" class="form-control-plaintext" id="nama" v-model="penjaga.nama" readonly>
                <label for="phone_no" class="form-label">Mobile Phone No<span class="text-danger">*</span></label>
                <input type="text" class="form-control" :class="(formErrors.phone_no) ? 'is-invalid' : ''" id="phone_no"
                    v-model="penjaga.phone_no">
                <div v-if="formErrors.phone_no" class="invalid-feedback">
                    {{ formErrors.phone_no }}
                </div>
                <a href="#" @click="confirm(label)"
                    class="mb-3 btn btn-full btn-m shadow-l rounded-s bg-green-dark mt-3 text-uppercase font-900"
                    :disabled="searching">Confirm</a>
            </div>
        </div>
    </div>
</template>
<script>
import { doc, getDoc, setDoc, Timestamp } from "firebase/firestore";
import { db } from '@/firebase.js';
import { useAuthStore } from '@/stores'

export default {
    name: 'ProfileEditView',
    data () {
        return {
            consent: false,
            label: '',
            loading: true,
            penjaga: {
                icno:'',
                nama:'',
                phone_no:''
            },
            profile: {
                email:'',
                tempat_pengajian: '',
                university: '',
                student: '',
                status_pekerjaan: '',
                jawatan: '',
                location: '',
                organisasi: '',
                gaji: '',
                phone_no: '',
                address: '',
                tahap_pekerjaaan: '',
                bidang_pekerjaaan: '',
                sektor_pekerjaan: '',
                bapa_nama : '',
                bapa_phone_no : '',
                bapa_icno : '',
                ibu_nama: '',
                ibu_phone_no: '',
                ibu_icno: '',
                
            },
            data: [],
            found: false,
            error: false,
            alumni: {},
            message: '',
            searching: false,
            searchResult: {},
            formErrors: {}
        }
    },
    setup () {
        const authStore = useAuthStore()
        const user = authStore.user
        return {
            user
        }

    },
    async created () {
        this.profile = await this.readData(this.user)
        if (this.profile.phone_no == null) { this.profile.phone_no = this.user.phone_no }
        this.loading = false

    },
    methods: {
        formsearch (status,label) {
            this.label = label
            this.found = false
            this.penjaga = {}
            if(label == 'bapa'){
                this.profile.bapa_alumni = ''                 
            }  
            else{
                this.profile.ibu_alumni = ''
            } 
            if (status == 'true') {
                document.getElementById('verifyPopup').classList.add('menu-active');
                document.getElementsByClassName('menu-hider')[0].classList.add('menu-active');
            } else {
                document.getElementById('verifyPopup').classList.remove('menu-active');
                document.getElementsByClassName('menu-hider')[0].classList.remove('menu-active');
                if (label == 'bapa'){
                    this.profile.bapa_icno = '';
                    this.profile.bapa_nama = '';
                    this.profile.bapa_phone_no = '';
                } 
                else{
                    this.profile.ibu_icno = '';
                    this.profile.ibu_nama = '';
                    this.profile.ibu_phone_no = '';
                }
            }
        },
        confirm(label){
            if(label == 'bapa'){
                this.profile.bapa_nama = this.penjaga.nama
                this.profile.bapa_phone_no = this.penjaga.phone_no
                this.profile.bapa_icno = this.penjaga.icno
            }else{
                this.profile.ibu_nama = this.penjaga.nama
                this.profile.ibu_phone_no = this.penjaga.phone_no
                this.profile.ibu_icno = this.penjaga.icno
            }
            document.getElementById('verifyPopup').classList.remove('menu-active');
            document.getElementsByClassName('menu-hider')[0].classList.remove('menu-active');
        },
        verify () {
            this.formErrors = {}; // Clear previous errors

            if (!this.penjaga.icno) {
                this.formErrors.icno = 'IC/Passport/Student No. is required.';
            }
            else {
                this.searching = true
                const config = {
                    headers: { Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2ODQzMDY1MDYsInNjb3BlIjoiL2FsdW1uYWkiLCJzdWIiOiJhbHVtbmFpIiwicGVtb2hvbiI6Ik1PSEQgQVpSVUwgQU1JUiBCSU4gTVVIQU1BRCBUQUpVRElOIiwibm9wZWtlcmphIjoiMjYxODUyIn0.t20JEOelzQEfZtdKnFzjMjmJmjPiXbf7yofpI6irhdY` }
                };
                const icno = this.penjaga.icno;

                this.axios
                    .get(
                        "https://fastapi.uitm.edu.my/alumnai/semak?nokp=" +
                        this.penjaga.icno, config
                    )
                    .then(async (response) => {
                        //298726
                        if (response.data.stat == true) {
                            this.alumni_id = response.data.alumni_id
                            const docRef = doc(db, "alumni", `${response.data.alumni_id}`);
                            const docSnap = await getDoc(docRef);

                            if (docSnap.exists()) {
                                this.error = false
                                this.found = true
                                this.penjaga.nama = docSnap.data().nama;
                                this.penjaga.phone_no = docSnap.data().phone_no;
                                this.penjaga.icno = icno
                                this.searching = false
                            } else {
                                this.error = true
                                this.searching = false
                                this.message = "Sorry, data not found. Please contact our administrator at alumniuitm@uitm.edu.my or <a href='https://wa.me/60355442780' target='_blank'><i class='fab fa-whatsapp'></i> Whatsapp</a>"
                            }

                        } else {
                            this.error = true
                            this.message = "Sorry, data not found. Please contact our administrator at alumniuitm@uitm.edu.my or <a href='https://wa.me/60355442780' target='_blank'><i class='fab fa-whatsapp'></i> Whatsapp</a>"

                            this.searching = false
                        }
                    }).catch((error) => {
                        this.loading = false;
                        this.message =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    });

            }

        },
        async readData (param) {
            const data = await getDoc(
                doc(db, "graduates", `${param.alumni_id}`)
            );

            if (data.exists()) {
                return data.data()
            }else{
                this.$router.push('convo')
            }
        },
        async submitForm () {
            if (this.validateForm()) {
                await setDoc(doc(db, 'graduates', `${this.profile.alumni_id}`), { 
                    'confirm_at': Timestamp.now(), 
                    'phone_no': this.profile.phone_no, 
                    'bapa_alumni': this.profile.bapa_alumni, 
                    'ibu_alumni': this.profile.ibu_alumni,
                    'bapa_icno': this.profile.bapa_icno,
                    'bapa_nama': this.profile.bapa_nama,
                    'bapa_phone_no': this.profile.bapa_phone_no,
                    'ibu_icno': this.profile.ibu_icno,
                    'ibu_nama': this.profile.ibu_nama,
                    'ibu_phone_no': this.profile.ibu_phone_no,
                }, { merge: true }).then(() => {
                    this.$router.push('/convo')
                })
            }
        },
        validateForm () {
            let isValid = true;
            this.formErrors = {}; // Clear previous errors

            if (!this.profile.phone_no) {
                this.formErrors.phone_no = 'Mobile Phone No is required.';
                isValid = false;
            } else if (!this.isNumber(this.profile.phone_no)) {
                this.formErrors.phone_no = 'Invalid Mobile Phone No format. Digit only';
                isValid = false;
            }
            
            if (!this.profile.ibu_alumni) {
                this.formErrors.ibu_alumni = 'Mother is a UiTM alumni is required.';
                isValid = false;
            }

            if (!this.profile.formFile) {
                this.formErrors.formFile = 'SKPG form is required.';
                isValid = false;
            }

            if (!this.profile.bapa_alumni) {
                this.formErrors.bapa_alumni = 'Father is a UiTM alumni is required.';
                isValid = false;
            }
            return isValid;
        },
        isValidEmail (email) {
            // Basic email validation
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },
        isNumber (number) {
            // Basic number validation
            const numberRegex = /^[0-9]*$/;
            return numberRegex.test(number);
        }
    }

}
</script>