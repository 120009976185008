<script setup>
import { Nav, Alert,Footer } from '@/components';
</script>
<template>
  <div class="" :class="showForm">
    <Nav />
    <Alert />
    <router-view />
    <Footer />

  </div>

  <div id="update-version" class="menu menu-box-bottom menu-box-detached rounded-m" style="display: block;">
    <div class="menu-title">
      <h1 class="font-20 color-green-dark mb-2 pt-3"><i class="fa fa-check-circle font-17 me-2"></i>New Update
        Available
      </h1>
    </div>

    <div class="content mt-n2">
      <a href="#" @click="refreshApp"
        class="btn btn-m font-800 rounded-sm btn-full text-uppercase bg-green-dark mb-4">Reload Now</a>
    </div>
    <div class="pb-2"></div>
  </div>

  <!-- Install Prompt for Android -->
  <div id="menu-install-pwa-android" class="menu menu-box-bottom menu-box-detached rounded-m menu-active"
    :style="{ display: deferredPromptAndroid }">
    <div class="boxed-text-l mt-4 pb-3">
      <img class="rounded-l mb-3" src="/images/icons/icon-128x128.png" alt="img" width="90">
      <h4 class="mt-3">Add MyAlumni on your Home Screen</h4>
      <p>
        Install MyAlumni on your home screen, and access it just like a regular app. It really is that simple!
      </p>
      <a href="#" @click="install"
        class="pwa-install btn btn-s rounded-s shadow-l text-uppercase font-900 bg-highlight mb-2">Add to Home
        Screen</a><br>
      <a href="#" @click="dismiss"
        class="pwa-dismiss close-menu color-gray-dark text-uppercase font-900 opacity-60 font-10 pt-2">Maybe
        later</a>
      <div class="clear"></div>
    </div>
  </div>

  <!-- Install instructions for iOS -->
  <div id="menu-install-pwa-ios" class="menu menu-box-bottom menu-box-detached rounded-m menu-active"
    :style="{ display: deferredPromptIphone }">
    <div class="boxed-text-xl mt-4 pb-3">
      <img class="rounded-l mb-3" src="/images/icons/icon-128x128.png" alt="img" width="90">
      <h4 class="mt-3">Add MyAlumni on your Home Screen</h4>
      <p class="mb-0 pb-0">
        Install MyAlumni, and access it like a regular app. Open your Safari menu and tap "Add to Home Screen".
      </p>
      <div class="clearfix pt-3"></div>
      <a href="#" @click="dismiss" class="pwa-dismiss close-menu color-highlight text-uppercase font-700">Maybe
        later</a>
    </div>
  </div>

</template>
<script>
export default {
  computed: {
    showForm () {
      if (this.$route.name == 'login' || this.$route.name == 'PageNotFound' || this.$route.name == 'convo')
        return ''
      else if (this.$route.name == 'terms' || this.$route.name == 'inapp' || this.$route.name == 'register' || this.$route.name == 'privacy' || this.$route.name == 'convo')
        return 'page-content header-clear-small'
      else
        return 'page-content header-clear-medium'
    }
  },
  data () {
    return {
      deferredPromptAndroid: 'none',
      deferredPromptIphone: 'none',
      deferredPrompt: null,
      refreshing: false,
      registration: null,
    };
  },
  created () {
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      // Here the actual reload of the page occurs
      window.location.reload()
    })

    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      this.deferredPrompt = e;
      // Stash the event so it can be triggered later.
      if (this.$cookies.isKey("myalumni-add-to-home-screen") == false) {
        if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
          this.deferredPromptAndroid = 'block';
        } else if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
          this.deferredPromptIphone = 'block';
        }
      }
      window.addEventListener("appinstalled", () => {
        this.deferredPrompt = null;
      });
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPromptAndroid = 'none';
      this.deferredPromptIphone = 'none';
    });
  },
  methods: {
    updateAvailable (event) {
      this.registration = event.detail
      document.getElementById('update-version').classList.add('menu-active');
      document.getElementsByClassName('menu-hider')[0].classList.add('menu-active');
    },
    refreshApp () {
      document.getElementById('update-version').classList.remove('menu-active');
      document.getElementsByClassName('menu-hider')[0].classList.remove('menu-active');
      if (!this.registration || !this.registration.waiting) return
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    async dismiss () {
      this.$cookies.set("myalumni-add-to-home-screen", "", { expireTimes: "1d"});
      this.deferredPromptAndroid = 'none';
      this.deferredPromptIphone = 'none';
      this.deferredPrompt = null;
    },
    async install () {
      

      if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
        this.deferredPrompt.prompt();
        this.deferredPromptAndroid = 'block';
      } else if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
        this.deferredPrompt.prompt();
        this.deferredPromptIphone = 'block';
      }
    }

  }
};
</script>
