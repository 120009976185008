import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyD9GRDvLT07NwEfINDdclJ6GRUxxphN2UE",
  authDomain: "myalumnicard.uitm.edu.my",
  projectId: "alumniuitmapp",
  storageBucket: "alumniuitmapp.appspot.com",
  messagingSenderId: "463075997276",
  appId: "1:463075997276:web:997d54d235f61748615899",
  measurementId: "G-ZD5CVKVM0D"
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const messaging = getMessaging(firebaseApp);
export { firebaseApp, auth, db, messaging };