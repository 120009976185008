<script setup>
import { storeToRefs } from 'pinia'

import { useAuthStore } from '@/stores'

const authStore = useAuthStore()
const { user } = storeToRefs(authStore)
</script>
<template>
  <div data-card-height="240" style="height: 240px; background-image: url('/images/pictures/18w.jpg');"
    class="card card-style rounded-m shadow-xl preload-img">
    <div class="card-top mt-4 ms-3">
      <img src="/images/logo-light-small.png" class="mt-1 shadow-l" height="30">
    </div>
    <div class="card-top mt-4 me-3">
      <img src="/images/alumni.png" class="mt-1  float-end shadow-l" width="35">
    </div>
    <div class="card-center text-center">
      <h1 class="color-white fa-2x">{{ user.nama }}</h1>
    </div>
    <div class="card-bottom">
      <p class="ms-3 font-11 font-500 opacity-50 color-white mb-2">Alumni ID: {{ user.alumni_id }}</p>
    </div>
    <div class="card-overlay bg-black opacity-70"></div>
  </div>
</template>