<template>
    <div id="preloader" v-if="loading">
        <div class="spinner-border color-highlight" role="status"></div>
    </div>
    <div class="card card-style bg-22 mx-0 rounded-0 position-fixed" data-card-height="450"
        style="top: 0px; width: 100%; height: 450px;">
        <div class="d-flex">
            <div class="align-self-center">
                <h1 class="card-top header-clear mt-2 mx-3 color-white mb-2 font-30 text-uppercase  font-600 lh-sm"
                    style="max-width: 78%;">
                    {{ convo.nama }}
                </h1>
            </div>
            <div class="align-self-center">
                <div class="card-top header-clear">
                    <strong class="float-end text-center">
                        <div class="bg-theme rounded-sm color-theme shadow-xl text-center m-3 overflow-hidden">
                            <span class="bg-red-dark font-16 d-block mb-2 px-3 line-height-xs py-1">{{bulan }}</span>
                            <span class="font-30 font-800 d-block mb-n3 line-height-s pb-2">{{ hari }}</span>
                        </div>
                    </strong>
                </div>
            </div>
        </div>
        <div class="card-bottom mb-4 no-click">
            <div class="content no-click w-60">
                <span class="bg-highlight p-2  mb-1 font-20 rounded-xs text-uppercase font-600">SESSION {{ convo.sidang
                    }}</span>
                <h1 class="color-white mt-2 font-40"> {{ setting.tajuk }}</h1>
                <h2 class="color-white opacity-50 font-13 mt-n2 font-400"><i class="fa fa-map-marker pe-2 "></i>{{
                    convo.tempat }}</h2>
            </div>
        </div>
        <div class="card-overlay bg-gradient rounded-0"></div>
    </div>
    <div class="card card-style bg-transparent shadow-0 no-click" data-card-height="390" style="height: 390px;"></div>

    <div class="page-content pb-0 mb-4">
        <div class="card card-style mx-0">
            <div class="divider mx-auto mt-3 bg-gray-dark opacity-30 rounded-s mb-n1" style="height:5px; width:50px;">
            </div>
            <a :href="convo.surat_jemputan" target="_blank" aria-expanded="false"
                class="no-effect card card-style mb-2">
                <div class="content">
                    <div class="d-flex mb-n1">
                        <div>
                            <h3>Invitation letter</h3>
                            <p class="opacity-80 font-10 mt-n2">Download the convocation ceremony invitation letter</p>
                        </div>
                        <div class="ms-auto align-self-center text-center opacity-70">
                            <i class="fa fa-download color-red-dark fa-2x mt-1"></i><br>
                        </div>
                    </div>
                </div>
            </a>
            <router-link to="/attend" aria-expanded="false" class="no-effect card card-style mb-2"
                :class="(convo.confirm_at) ? 'bg-green-dark':''">
                <div class="content">
                    <div class="d-flex mb-n1">
                        <div>
                            <h3 :class="(convo.confirm_at) ? 'text-white' : ''">Attendance confirmation</h3>
                            <p class="opacity-80 font-10 mt-n2" :class="(convo.confirm_at) ? 'text-white' : ''">Please
                                fill out the attendance confirmation form</p>
                        </div>
                        <div class="ms-auto align-self-center text-center opacity-70">
                            <i class="fa fa-check  fa-2x mt-1"
                                :class="(convo.confirm_at) ? 'color-white' : 'color-red-dark'"></i><br>
                        </div>
                    </div>
                </div>
            </router-link>
            <a :href="convo.borang_jubah" target="_blank" aria-expanded="false" class="no-effect card card-style mb-2">
                <div class="content">
                    <div class="d-flex mb-n1">
                        <div>
                            <h3>Robe collection form</h3>
                            <p class="opacity-80 font-10 mt-n2">Download Robe collection form</p>
                        </div>
                        <div class="ms-auto align-self-center text-center opacity-70">
                            <i class="fa fa-clipboard color-red-dark fa-2x mt-1"></i><br>
                        </div>
                    </div>
                </div>
            </a>
            <a :href="convo.kad_kemputan" v-if="convo.bapa_alumni == 'true' || convo.ibu_alumni == 'true'"
                target="_blank" aria-expanded="false" class="no-effect card card-style mb-2">
                <div class="content">
                    <div class="d-flex mb-n1">
                        <div>
                            <h3>Invitation card</h3>
                            <p class="opacity-80 font-10 mt-n2">Download Invitation Card</p>
                        </div>
                        <div class="ms-auto align-self-center text-center opacity-70">
                            <i class="fa fa-address-card color-red-dark fa-2x mt-1"></i><br>
                        </div>
                    </div>
                </div>
            </a>
            <a @click="checkin" aria-expanded="false" class="no-effect card card-style mb-2">
                <div class="content">
                    <div class="d-flex mb-n1">
                        <div>
                            <h3>Check-In</h3>
                            <p class="opacity-80 font-10 mt-n2">Please check-in to confirm your attendance</p>
                        </div>
                        <div class="ms-auto align-self-center text-center opacity-70">
                            <i class="fa fa-location-pin color-red-dark fa-2x mt-1"></i><br>
                        </div>
                    </div>
                </div>
            </a>
            <a :href="setting.borang_maklumbalas" target="_blank" aria-expanded="false"
                class="no-effect card card-style mb-2">
                <div class="content">
                    <div class="d-flex mb-n1">
                        <div>
                            <h3>Feedback form</h3>
                            <p class="opacity-80 font-10 mt-n2">Fill out the feedback form before returning the robe</p>
                        </div>
                        <div class="ms-auto align-self-center text-center opacity-70">
                            <i class="fa fa-comments color-red-dark fa-2x mt-1"></i><br>
                        </div>
                    </div>
                </div>
            </a>

            <div class="divider divider-margins"></div>

            <div class="card card-style">
                <div class="content my-0">
                    <div class="list-group list-custom-small check-visited" v-for="(menu, index) in menus" :key="index">
                        <a :href="menu.url" target="_blank"><i class="fa color-red-dark"
                                :class="menu.icon"></i><span>{{menu.title}}</span><i class="fa fa-angle-right"></i></a>

                    </div>
                </div>
            </div>


        </div>
    </div>
    <!-- End of Page Content-->
</template>
<script>
import { doc, getDoc } from "firebase/firestore";
import { db } from '@/firebase.js';
import { useAuthStore, useAlertStore } from '@/stores'

export default {
    name: 'ConvoView',
    data () {
        return{
            loading: true,
            setting: {},
            convo: {},
            center: { lat: 0, lng: 0 }, // Initial center of the map
            radius: 1000, // Set radius in meters
            currentPosition: null,
            distance: 0, 
            bulan: 0,
            hari: 0,
            menus: [],
            targetLocation: { lat: 3.0659442, lng: 101.5023429 } // Example target location
        }
    },
    setup () {
        const authStore = useAuthStore()
        const user = authStore.user
        
        return {
            user
        }

    },
    async created () {
        this.convo = await this.readUser(this.user.alumni_id)
        const tarikh = new Date(this.convo.tarikh.seconds * 1000)
        const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        this.bulan = month[tarikh.getMonth()];
        this.hari = tarikh.getDate()
        this.setting = await this.readSetting();
        this.menus = this.setting.menu.sort(
            (p1, p2) => (p1.sort > p2.sort) ? 1 : (p1.sort < p2.sort) ? -1 : 0);
        this.distance = this.calculateDistance();
        this.loading = false

    },
    methods: {
        async readUser (param) {

            const data = await getDoc(
                doc(db, "graduates", `${param}`)
            );

            if (data.exists()) {
                this.open = true
                return data.data()
            }else{
                this.$router.push('/')
            }
        },
        async readSetting () {

            const data = await getDoc(
                doc(db, "myalumni", "convocation")
            );

            if (data.exists()) {

                if (new Date(data.data().end_date.seconds * 1000) >= new Date(Date.now()) && new Date(data.data().start_date.seconds * 1000) <= new Date(Date.now())) {
                    this.targetLocation = { lat: data.data().lat, lng: data.data().lng }
                    this.radius = data.data().radius
                } else {
                    this.$router.push('/')
                }
                return data.data()
            }
        },
        calculateDistance () {
            
            if (this.currentPosition) {
                const lat1 = this.currentPosition.coords.latitude;
                const lon1 = this.currentPosition.coords.longitude;
                const lat2 = this.targetLocation.lat;
                const lon2 = this.targetLocation.lng;

                const R = 6371e3; // Radius of the Earth in meters
                const φ1 = (lat1 * Math.PI) / 180;
                const φ2 = (lat2 * Math.PI) / 180;
                const Δφ = ((lat2 - lat1) * Math.PI) / 180;
                const Δλ = ((lon2 - lon1) * Math.PI) / 180;

                const a =
                    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
                    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
                const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

                const distance = R * c;

                return distance;                
            } else {
                return null;
            }
        },
        checkin(){
            const alertStore = useAlertStore()
            if (this.distance >= this.radius) {
                alertStore.error('Your current location out of distance');
            } else {
                alertStore.success('Check-in success');
            }
        }
    },
    mounted () {
        // Get the user's current location
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(position => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                this.currentPosition = position;
            });
        }
    },
}
</script>