<template>

    <div data-card-height="cover" :style="{ height: coverHeight }"  class="card">
        <div class="card-center text-center">
            <h1 class="center-text mb-5"><i class="fa fa-3x fa-exclamation-triangle color-red-dark "></i></h1>
            <h1 class="center-text fa-5x mb-3">ERROR</h1>
            <h6 class="center-text font-800 font-14 mb-4">WOOPS! 404 - PAGE NOT FOUND. </h6>

            <p class="boxed-text-l mb-5 opacity-80">
                We're sorry, the page you requested cound not be found. Please go back to the homepage or contact us.
            </p>
            <div class="row me-5 ms-5 mb-0">
                <div class="col-6">
                    <router-link to="/"
                        class="back-button ms-2 btn btn-m bg-highlight rounded-sm btn-full text-uppercase font-900 shadow-l">HOME</router-link>
                </div>
                <div class="col-6">
                    <a href="https://www.facebook.com/alumniuitm" target="_blank"
                        class="back-button me-2 btn btn-m bg-highlight rounded-sm btn-full text-uppercase font-900 shadow-l">CONTACT</a>
                </div>
                <div class="claerfix"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'page_not_found',
    data () {
        return {
            coverHeight: 0,
            windowHeigh: 0
        }
    },
    created () {
        if (window.matchMedia('(display-mode: fullscreen)').matches) { this.windowHeight = window.outerHeight; }
        if (!window.matchMedia('(display-mode: fullscreen)').matches) { this.windowHeight = window.innerHeight; }
        //Fix for iOS 15 pages with data-height="cover"
        this.coverHeight = this.windowHeight + 'px';
    },
}
</script>
