import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "@/assets/style.css"
import { createApp } from "vue";
import { createPinia } from 'pinia'
import App from "./App.vue";
import "./registerServiceWorker";
import router from './router'
import 'firebase/auth'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue3-cookies'

const app = createApp(App)
app.use(router)
app.use(createPinia())
app.use(VueAxios, axios)
app.use(VueCookies, {
    expireTimes: "30d",
    path: "/",
    domain: "myalumnicard.uitm.edu.my",
    secure: true,
    sameSite: "Strict"
});
app.mount('#app')
