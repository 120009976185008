<template>
  <div id="preloader" v-if="loading">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>

  <div class="card card-style preload-img" data-card-height="130"
    style="background-image: url('/images/pictures/18w.jpg'); height: 130px">
    <div class="card-center ms-3">
      <h1 class="color-white mb-0">UiTM Alumni Business</h1>
    </div>
    <div class="card-overlay bg-black opacity-80"></div>
  </div>
  <div class="card card-style gradient-blue">
    <div class="content pt-2 pb-5">
      <div class="card-center ms-3">
        <p class="color-white mt-n1 mb-0 w-50">Interested in publicizing your business here?</p>
      </div>
      <div class="card-center me-3">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfunIcE4n_URf0JCRotYRipkHa8IR-y4VxaIU38p4x0cdGAiA/viewform"
          class="btn btn-m float-end rounded-xl shadow-xl text-uppercase font-800 bg-highlight">Listing Now</a>
      </div>
    </div>
  </div>

  <div class="search-page">

    <div class="search-box search-header bg-theme card-style me-3 ms-3">
      <i class="fa fa-search"></i>
      <input type="text" v-model="searchValue" @keyup="searchResult($event.target.value)" class="border-0"
        placeholder="What are you looking for? ">
      <a href="#" class="mt-0" @click="searchValue = ''; searchResult($event.target.value)"><i
          class="fa fa-times color-red-dark"></i></a>
    </div>

    <div class="card card-style" v-for="(business, index) in tempBusinesses" :key="index">
      <div class="content mt-0">
        <div class="d-flex py-3">
          <div class="align-self-center">
            <img :src="business.image" onerror="this.onerror=null; this.src='images/Image-Not-Available.png'"
              class="rounded-circle me-3" width="45">
          </div>
          <div class="align-self-center">
            <h6 class="mb-n1 pt-1 font-16">{{ business.name }}</h6>
            <span class="font-11 opacity-50">{{ business.nature }}</span>
          </div>
          <div class="align-self-center ms-auto">
            <a :href="business.url" target="_blank" class="btn btn-xs bg-highlight rounded-m">Website</a>
          </div>
        </div>

        <p>{{ business.description }}</p>
        <div class="row mb-3">
          <div class="col-5">
            <div class="d-flex">
              <div class="align-self-center">
                <i style="width:20px" class="fa fa-phone color-teal-dark font-23 me-3 text-center"></i>
              </div>
              <div class="align-self-center">
                <span class="d-block font-10 mb-n3 pb-1 color-theme opacity-50">Phone No</span>
                <strong class="d-block font-12 pb-1 color-theme">{{ business.phone }}</strong>
              </div>
            </div>
          </div>
          <div class="col-7">
            <div class="d-flex">
              <div class="align-self-center">
                <i style="width:20px" class="fa fa-envelope color-blue-dark font-23 me-3 text-center"></i>
              </div>
              <div class="align-self-center">
                <span class="d-block font-10 mb-n3 pb-1 color-theme opacity-50">Email</span>
                <strong class="d-block font-12 pb-1 color-theme">{{ business.email }}</strong>
              </div>
            </div>
          </div>
          <div class="col-12 mb-2"></div>
          <div class="col-12">
            <div class="d-flex">
              <div class="align-self-center">
                <i style="width:20px" class="fa fa-map-marker color-yellow-dark font-23 me-3 text-center"></i>
              </div>
              <div class="align-self-center">
                <span class="d-block font-10 mb-n3 pb-1 color-theme opacity-50">Address</span>
                <strong class="d-block font-12 pb-1 color-theme">{{ business.address }}</strong>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>
<script>
import { doc, getDoc } from "firebase/firestore";
import { db } from '@/firebase.js';
export default {
  name: 'BusinessView',
  data () {
    return {
      loading: true,
      businesses: [],
      tempBusinesses: [],
      id: this.$route.params.id,
      showError: false,
      searchValue: ''
    };
  },
  async created(){
    this.businesses = await this.readData()
    this.tempBusinesses = await this.readData()
    this.loading = false;
  },
  methods: {
     searchResult (searchValue) {
      
      let tempBusinesses = this.businesses

      if (searchValue != '' && searchValue) {
        tempBusinesses = tempBusinesses.filter((item) => {
          return item.name.toUpperCase().includes(searchValue.toUpperCase()) || item.nature.toUpperCase().includes(searchValue.toUpperCase())
        })
      }else{
        this.tempBusinesses = this.businesses
      }
      this.tempBusinesses = tempBusinesses
    },
    async readData () {
      const businesses = await getDoc(
        doc(db, "myalumni", "business")
      );

      if (businesses.exists()) {
        let tempBusiness = businesses.data().companies

        tempBusiness = tempBusiness.filter((item) => {
          return (item.status == true)
        })
        

        tempBusiness = tempBusiness.sort((a, b) => {
          let fa = a.name.toLowerCase(), fb = b.name.toLowerCase();
          if (fa < fb) {
            return -1
          }
          if (fa > fb) {
            return 1
          }
          return 0
        })


        

        return tempBusiness;
      } else {
        alert('Fail to load data')
      }
    },
  },
}
</script>
