<script setup>
import { storeToRefs } from 'pinia';

import { useAlertStore } from '@/stores';

const alertStore = useAlertStore();
const { alert } = storeToRefs(alertStore);
</script>

<template>
    <div id="notification-5" data-bs-dismiss="notification-5"
        class="toast w-auto notification shadow-xl notch-push mt-3 show" v-if="alert">
        <div class="toast-header"
            :class="(alert.type == 'success')?'bg-green-dark border-green-dark':'bg-red-dark border-red-dark'">
            <strong class="me-auto color-white">{{ (alert.type == 'success') ? 'Success':'Error' }}</strong>
            <button type="button" class="close color-white opacity-60 font-16" @click="alertStore.clear()"
                aria-label="Close">×</button>
        </div>
        <div class="toast-body"
            :class="(alert.type == 'success') ? 'bg-green-dark' : 'bg-red-dark'">
            {{ alert.message }}
        </div>
    </div>
</template>