import { defineStore } from 'pinia'
import router from '@/router'
import { useAlertStore } from '@/stores'
import { auth } from '@/firebase.js';
import { signOut,onAuthStateChanged } from 'firebase/auth';

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    user: JSON.parse(localStorage.getItem('user')),
    returnUrl: null
  }),
  actions: {
    async login(result) {
      try {
        // update pinia state
        this.user = result

        // store user details and jwt in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(result))

        // redirect to previous url or default to home page
        router.push(this.returnUrl || '/')
      } catch (error) {
          const alertStore = useAlertStore()
          alertStore.error(error)
      }
    },
    getCurrentUser() {
      return new Promise((resolve, reject) => {
        const unsubscribe = onAuthStateChanged(
          auth,
          (user) => {
            unsubscribe()
            resolve(user)
          },
          reject
        )
      })
    },
    logout() {
      this.user = null
      signOut(auth);
      localStorage.removeItem('user')
      router.push('/login')
    }
  }
})