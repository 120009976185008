import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import LoginView from "@/views/LoginView.vue";
import RegisterView from "@/views/RegisterView.vue";
import { useAuthStore, useAlertStore } from '@/stores';
import CardView from "@/views/CardView.vue";
import BusinessView from "@/views/BusinessView.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import PrivacyView from "@/views/PrivacyView.vue";
import TermView from "@/views/TermView.vue";
import BenefitView from "@/views/BenefitView.vue";
import ProfileView from "@/views/ProfileView.vue";
import BenefitDetailsView from "@/views/BenefitDetailsView.vue";
import ProfileEditView from "@/views/ProfileEditView.vue";
import ConvoView from "@/views/ConvoView.vue";
import ConvoAttendView from "@/views/ConvoAttendView.vue";
import InappView from "@/views/InappView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  { path: '/login', 
    name: "login",
    component: LoginView 
  },
  { path: '/register', 
    name: "register",
    component: RegisterView 
  },
  { path: '/card', 
    name: "card",
    component: CardView
  },
  { path: '/business', 
    name: "business",
    component: BusinessView
  },
  { path: '/privacy', 
    name: "privacy",
    component: PrivacyView
  },
  { path: '/terms', 
    name: "terms",
    component: TermView
  },
  {
    path: "/benefits",
    name: "benefits",
    component: BenefitView,
  },
  {
    path: "/benefits/:id",
    name: "benefitDetails",
    component: BenefitDetailsView
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfileView,
  },
  {
    path: "/profile/edit",
    name: "profileEdit",
    component: ProfileEditView,
  },
  {
    path: "/convo",
    name: "convo",
    component: ConvoView,
  },
  {
    path: "/attend",
    name: "attend",
    component: ConvoAttendView,
  },
  {
    path: '/notsupported',
    name: 'inapp',
    component: InappView
  },
  {
    path: "/:catchAll(.*)",
    name: "PageNotFound",
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach(async (to) => {
    // clear alert on route change
    const alertStore = useAlertStore();
    const authStore = useAuthStore()
    alertStore.clear();

    // redirect to login page if not logged in and trying to access a restricted page 
    const publicPages = ['/login', '/privacy','/terms','/register','/notsupported'];
    const authRequired = !publicPages.includes(to.path);
    if (authRequired && !(await authStore.getCurrentUser()) ) {
        authStore.returnUrl = to.fullPath;
        authStore.logout()
        return 'login';
    }if (authRequired && !authStore.user ) {
        authStore.returnUrl = to.fullPath;
        return 'login';
    }
});
export default router;
