<template>
        <div data-card-height="280" class="card card-style bg-13" style="height: 280px;">
            <div class="card-center">
                <h1 class="text-center mb-3"><i class="fa fa-2x fa-frown color-red-light"></i></h1>
                <h1 class="text-center color-white bolder mb-2">In-App Browser Detected</h1>
                <p class="text-center color-white opacity-90 bottom-25">
                    Browser not supported. Please use another browser.
                </p>
                <a href="https://www.google.com/chrome/" target="_blank" class="btn btn-m rounded-xs btn-center-l bg-highlight text-uppercase font-900">Download Chrome</a>
            </div>
            <div class="card-overlay bg-black opacity-80"></div>
        </div>
</template>
<script>
export default {
    name: 'InappView',
    data () {
        return {
            loading: true,
            data: [],
            id: this.$route.params.id,
            showError: false
        };
    },
}
</script>