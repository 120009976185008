<template>
    <div id="preloader" v-if="loading">
        <div class="spinner-border color-highlight" role="status"></div>
    </div>
    <div class="card card-style">
        <img :src="data.banner" class="preload-img img-fluid bottom-20 entered loaded">
        <div class="content mb-0">
            <div class="float-start">
                <h1 class="mb-0">{{ data.title }}</h1>
                <p class="font-12"> {{ data.subtitle }}</p>
            </div>
            <span v-if="new Date(data.end_date?.seconds * 1000) >= new Date(Date.now())" class="badge rounded-pill bg-success float-end rounded-xl shadow-xl text-uppercase font-900 mt-2 font-11 p-2">Available</span>
            <span v-else class="badge rounded-pill bg-danger float-end rounded-xl shadow-xl text-uppercase font-900 mt-2 font-11 p-2">Expired</span>

            <div class="clearfix"></div>
            <div class="divider mt-2 mb-3"></div>

            <div class="row">
                <div class="col-6">
                    <strong class="color-theme">Start Date:</strong>
                    <p class="font-12"><i class="far fa-calendar me-2"></i>{{ start_date }}</p>
                </div>
                <div class="col-6">
                    <strong class="color-theme">End Date:</strong>
                    <p class="font-12"><i class="far fa-clock me-2"></i>{{ end_date}}</p>
                </div>
            </div>


            <h5>Description</h5>
            <p v-html="data.description">
            </p>
            <a :href="data.url" target="_blank" class="btn btn-m btn-full rounded-sm shadow-xl bg-highlight text-uppercase mb-3 font-900">REDEEM NOW</a>
        </div>
    </div>
</template>
<script>
import { doc, getDoc } from "firebase/firestore";
import { db } from "@/firebase.js";
import moment from "moment"

export default {
  name: 'BenefitDetailsView',
  data () {
    return {
      loading: true,
      data: {},
      id: this.$route.params.id,
      showError: false,
      start_date: '',
      end_date: '',
    };
  },
  async created () {
        const ID = Number(this.$route.params.id);
        await this.readData(ID);
        this.loading = false;
    },
    methods: {
        async readData (id) {
            const benefits = await getDoc(
                doc(db, "myalumni", "benefits")
            );

            if (benefits.exists()) {
                let tempBenefit = benefits.data().offers
                
                tempBenefit.filter((item) => {
                    if(item.id == id){
                        this.data = item
                        this.start_date =  moment(item.start_date?.seconds*1000, 'x').format('DD MMMM YYYY')
                        this.end_date = moment(item.end_date?.seconds*1000, 'x').format('DD MMMM YYYY')
                        
                    }else{
                        return null
                    }
                })
                

                //return tempBenefit;

                //return giving.data()
            } else {
                alert('Fail to load data')
            }

        },
    },
}
</script>
