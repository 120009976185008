<template>
    <div id="preloader" v-if="loading">
        <div class="spinner-border color-highlight" role="status"></div>
    </div>
    <div class="card card-style preload-img"
        style="height: 130px;background-image: url('/images/pictures/18w.jpg');" data-card-height="130">
        <div class="card-center ms-3">
            <h1 class="color-white mb-0">Edit Profile</h1>
        </div>
        <div class="card-center me-3">
            <router-link to="/profile"
                class="btn btn-m float-end rounded-xl shadow-xl text-uppercase font-800 bg-highlight">Back
            </router-link>
        </div>
        <div class="card-overlay bg-black opacity-80"></div>
    </div>

    <div class="card card-style">
        <div class="content">
            <form>
                <div class="mb-3">
                    <label for="phone_no" class="form-label">Mobile Phone No<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" :class="(formErrors.phone_no) ? 'is-invalid':''"
                        id="phone_no" v-model="profile.phone_no">
                    <div v-if="formErrors.phone_no" class="invalid-feedback">
                        {{ formErrors.phone_no }}
                    </div>
                </div>
                <div class="mb-3">
                    <label for="address" class="form-label">Address <span class="text-danger">*</span></label>
                    <textarea class="form-control" id="address" :class="(formErrors.address) ? 'is-invalid' : ''"
                        v-model="profile.address"></textarea>
                    <div v-if="formErrors.address" class="invalid-feedback">
                        {{ formErrors.address }}
                    </div>
                </div>
                <div class="mb-3">
                    <label for="status_pekerjaan" class="form-label">Current Employement Status <span
                            class="text-danger">*</span></label>
                    <select class="form-select" id="status_pekerjaan" v-model="profile.status_pekerjaan"
                        :class="(formErrors.status_pekerjaan) ? 'is-invalid' : ''">
                        <option value="" disabled selected>Select a Value</option>
                        <option value="Employed">Employed</option>
                        <option value="Self-employed">Self-employed</option>
                        <option value="Unemployed">Unemployed</option>
                    </select>
                    <div v-if="formErrors.status_pekerjaan" class="invalid-feedback">
                        {{ formErrors.status_pekerjaan }}
                    </div>
                </div>
                <div class="mb-3"
                    v-if="profile.status_pekerjaan == 'Employed' || profile.status_pekerjaan == 'Self-employed'">
                    <label for="sektor_pekerjaan" class="form-label">Employement Sector <span
                            class="text-danger">*</span></label>
                    <select class="form-select" id="sektor_pekerjaan" v-model="profile.sektor_pekerjaan"
                        :class="(formErrors.sektor_pekerjaan) ? 'is-invalid' : ''">
                        <option value="" disabled selected>Select a Value</option>
                        <option value="Public Sector">Public Sector</option>
                        <option value="Private Sector">Private Sector</option>
                    </select>
                    <div v-if="formErrors.sektor_pekerjaan" class="invalid-feedback">
                        {{ formErrors.sektor_pekerjaan }}
                    </div>
                </div>
                <div class="mb-3"
                    v-if="profile.status_pekerjaan == 'Employed' || profile.status_pekerjaan == 'Self-employed'">
                    <label for="bidang_pekerjaaan" class="form-label">Occupational Field <span
                            class="text-danger">*</span></label>
                    <select class="form-select" id="bidang_pekerjaaan" v-model="profile.bidang_pekerjaaan"
                        :class="(formErrors.bidang_pekerjaaan) ? 'is-invalid' : ''">
                        <option value="" disabled selected>Select a Value</option>
                        <option value="Agriculture">Agriculture</option>
                        <option value="Mining and quarrying">Mining and quarrying</option>
                        <option value="Manufacturing">Manufacturing</option>
                        <option value="Construction">Construction</option>
                        <option value="Services">Services</option>
                    </select>
                    <div v-if="formErrors.bidang_pekerjaaan" class="invalid-feedback">
                        {{ formErrors.bidang_pekerjaaan }}
                    </div>
                </div>
                <div class="mb-3"
                    v-if="profile.status_pekerjaan == 'Employed' || profile.status_pekerjaan == 'Self-employed'">
                    <label for="tahap_pekerjaaan" class="form-label">Employment Level <span
                            class="text-danger">*</span></label>
                    <select class="form-select" id="tahap_pekerjaaan" v-model="profile.tahap_pekerjaaan"
                        :class="(formErrors.tahap_pekerjaaan) ? 'is-invalid' : ''">
                        <option value="" disabled selected>Select a Value</option>
                        <option value="Skilled workers">Skilled workers (e.g managers/legislators, profesional and
                            technicians)
                        </option>
                        <option value="Semi skilled workers">Semi skilled workers (e.g clerical, service and sales)
                        </option>
                        <option value="Low-skilled workers">Low-skilled workers (e.g farmer and fishery, craft and
                            related trades,
                            plant and machine operators and elementary occupation)</option>
                    </select>
                    <div v-if="formErrors.tahap_pekerjaaan" class="invalid-feedback">
                        {{ formErrors.tahap_pekerjaaan }}
                    </div>
                </div>
                <div class="mb-3"
                    v-if="profile.status_pekerjaan == 'Employed' || profile.status_pekerjaan == 'Self-employed'">
                    <label for="gaji" class="form-label">Range of Salary <span class="text-danger">*</span></label>
                    <select class="form-select" id="gaji" v-model="profile.gaji"
                        :class="(formErrors.gaji) ? 'is-invalid' : ''">
                        <option value="" disabled selected>Select a Value</option>
                        <option value="Less than RM4,800">Less than RM4,800</option>
                        <option value="RM4,801-RM10,970">RM4,801-RM10,970</option>
                        <option value="More than RM10,970">More than RM10,970</option>
                    </select>
                    <div v-if="formErrors.gaji" class="invalid-feedback">
                        {{ formErrors.gaji }}
                    </div>
                </div>
                <div class="mb-3" v-if="profile.status_pekerjaan == 'Employed'">
                    <label for="jawatan" class="form-label">Designation/Post <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" id="jawatan" v-model="profile.jawatan"
                        :class="(formErrors.jawatan) ? 'is-invalid' : ''">
                    <div v-if="formErrors.jawatan" class="invalid-feedback">
                        {{ formErrors.jawatan }}
                    </div>
                </div>
                <div class="mb-3"
                    v-if="profile.status_pekerjaan == 'Employed' || profile.status_pekerjaan == 'Self-employed'">
                    <label for="organisasi" class="form-label">Name of Organisation <span
                            class="text-danger">*</span></label>
                    <input type="text" class="form-control" id="organisasi" v-model="profile.organisasi"
                        :class="(formErrors.organisasi) ? 'is-invalid' : ''">
                    <div v-if="formErrors.organisasi" class="invalid-feedback">
                        {{ formErrors.organisasi }}
                    </div>
                </div>
                <div class="mb-3"
                    v-if="profile.status_pekerjaan == 'Employed' || profile.status_pekerjaan == 'Self-employed'">
                    <label for="location" class="form-label">Location of Workplace <span
                            class="text-danger">*</span></label>
                    <input type="text" class="form-control" id="location" v-model="profile.location"
                        :class="(formErrors.location) ? 'is-invalid' : ''">
                    <div v-if="formErrors.location" class="invalid-feedback">
                        {{ formErrors.location }}
                    </div>
                </div>
                <div class="mb-3" v-if="profile.status_pekerjaan == 'Unemployed'">
                    <label for="student" class="form-label">Are you currently a full time student? <span
                            class="text-danger">*</span></label>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" v-model="profile.student" id="yes" value="true"
                            :class="(formErrors.student) ? 'is-invalid' : ''">
                        <label class="form-check-label" for="yes">
                            Yes
                            <span class="text-danger">*</span></label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" v-model="profile.student" id="no" value="false"
                            :class="(formErrors.student) ? 'is-invalid' : ''">
                        <label class="form-check-label" for="no">
                            No
                            <span class="text-danger">*</span></label>
                    </div>
                    <div v-if="formErrors.student" class="invalid-feedback">
                        {{ formErrors.student }}
                    </div>
                </div>
                <div class="mb-3" v-if="profile.status_pekerjaan == 'Unemployed' && profile.student == 'true'">
                    <label for=" university" class="form-label">University <span class="text-danger">*</span></label>
                    <select class="form-select" id="university" v-model="profile.university"
                        :class="(formErrors.university) ? 'is-invalid' : ''">
                        <option value="" disabled selected>Select a Value</option>
                        <option v-for="(university, index) in tempUniversities" :key="index" :value="university.id">
                            {{ university.name }} ({{ university.code }})</option>
                    </select>
                    <div v-if="formErrors.university" class="invalid-feedback">
                        {{ formErrors.university }}
                    </div>
                </div>
                <div class="mb-3"
                    v-if="profile.status_pekerjaan == 'Unemployed' && profile.student == 'true' && profile.university == '999'">
                    <label for="tempat_pengajian" class="form-label">Name of institution <span
                            class="text-danger">*</span></label>
                    <input type="text" class="form-control" id="tempat_pengajian" v-model="profile.tempat_pengajian"
                        :class="(formErrors.tempat_pengajian) ? 'is-invalid' : ''">
                    <div v-if="formErrors.tempat_pengajian" class="invalid-feedback">
                        {{ formErrors.tempat_pengajian }}
                    </div>
                </div>
                <a href="#" @click="submitForm" class="btn btn-m rounded-sm btn-full bg-highlight font-700
                    text-uppercase">Save</a>
            </form>
        </div>
    </div>
</template>
<script>
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from '@/firebase.js';
import { useAuthStore } from '@/stores'

export default {
    name: 'ProfileEditView',
    data () {
        return {
            consent: false,
            loading: true,
            profile: {
                tempat_pengajian: '',
                university: '',
                student: '',
                status_pekerjaan: '',
                jawatan: '',
                location: '',
                organisasi: '',
                gaji: '',
                phone_no: '',
                address: '',
                tahap_pekerjaaan: '',
                bidang_pekerjaaan: '',
                sektor_pekerjaan: '',
            },
            searchResult: {},
            universites: [],
            tempUniversities: [],
            formErrors: {}
        }
    },
    setup () {
        const authStore = useAuthStore()
        const user = authStore.user
        return {
            user
        }

    },
    async created () {
        this.profile = await this.readData(this.user)
        this.universites = await this.readDataUniversities()
        this.tempUniversities = await this.readDataUniversities()

        this.loading = false

    },
    methods: {
        async readDataUniversities () {
            const universites = await getDoc(
                doc(db, "myalumni", "parameters")
            );
            if (universites.exists()) {
                let tempUniversities = universites.data().universities
                tempUniversities = tempUniversities.sort((a, b) => {
                    let fa = a.name.toLowerCase(), fb = b.name.toLowerCase();
                    if (fa < fb) {
                        return -1
                    }
                    if (fa > fb) {
                        return 1
                    }
                    return 0
                })
                return tempUniversities;
            } else {
                alert('Fail to load data')
            }
        },
        async readData (param) {
            const data = await getDoc(
                doc(db, "alumni", `${param.alumni_id}`)
            );

            if (data.exists()) {
                return data.data()
            }
        },
        async submitForm () {
            if (this.validateForm()) {
                await setDoc(doc(db, 'alumni', `${this.profile.alumni_id}`), this.profile, { merge: true }).then(() => {
                    this.$router.push('/profile')
                })
            } else {
                // Do something if form validation fails
                console.log('Form validation failed.');
            }
        },
        validateForm () {
            let isValid = true;
            this.formErrors = {}; // Clear previous errors

            if (!this.profile.phone_no) {
                this.formErrors.phone_no = 'Mobile Phone No is required.';
                isValid = false;
            } else if (!this.isNumber(this.profile.phone_no)) {
                this.formErrors.phone_no = 'Invalid Mobile Phone No format. Digit only';
                isValid = false;
            }

            if (!this.profile.address) {
                this.formErrors.address = 'Address No is required.';
                isValid = false;
            }

            if (!this.profile.status_pekerjaan) {
                this.formErrors.status_pekerjaan = 'Employment status is required.';
                isValid = false;
            }

            if ((this.profile.status_pekerjaan == 'Employed' || this.profile.status_pekerjaan == 'Self-employed') && !this.profile.sektor_pekerjaan) {
                this.formErrors.sektor_pekerjaan = 'Employement Sector is required.';
                isValid = false;
            }

            if ((this.profile.status_pekerjaan == 'Employed' || this.profile.status_pekerjaan == 'Self-employed') && !this.profile.bidang_pekerjaaan) {
                this.formErrors.bidang_pekerjaaan = 'Occupational Field is required.';
                isValid = false;
            }

            if ((this.profile.status_pekerjaan == 'Employed' || this.profile.status_pekerjaan == 'Self-employed') && !this.profile.tahap_pekerjaaan) {
                this.formErrors.tahap_pekerjaaan = 'Employment Level is required.';
                isValid = false;
            }

            if ((this.profile.status_pekerjaan == 'Employed' || this.profile.status_pekerjaan == 'Self-employed') && !this.profile.gaji) {
                this.formErrors.gaji = 'Range of Salary is required.';
                isValid = false;
            }

            if (this.profile.status_pekerjaan == 'Employed' && !this.profile.jawatan) {
                this.formErrors.jawatan = 'Designation/Post is required.';
                isValid = false;
            }

            if ((this.profile.status_pekerjaan == 'Employed' || this.profile.status_pekerjaan == 'Self-employed') && !this.profile.organisasi) {
                this.formErrors.organisasi = 'Name of Organisation is required.';
                isValid = false;
            }

            if ((this.profile.status_pekerjaan == 'Employed' || this.profile.status_pekerjaan == 'Self-employed') && !this.profile.location) {
                this.formErrors.location = 'Location of Workplace is required.';
                isValid = false;
            }

            if (this.profile.status_pekerjaan == 'Unemployed' && !this.profile.student) {
                this.formErrors.student = 'Full time student status is required.';
                isValid = false;
            }

            if (this.profile.status_pekerjaan == 'Unemployed' && this.profile.student == 'true' && !this.profile.university) {
                this.formErrors.university = 'University is required.';
                isValid = false;
            }

            if (this.profile.status_pekerjaan == 'Unemployed' && this.profile.student == 'true' && this.profile.university == '999' && !this.profile.tempat_pengajian) {
                this.formErrors.university = 'Name of institution is required.';
                isValid = false;
            }
            return isValid;
        },
        isValidEmail (email) {
            // Basic email validation
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },
        isNumber (number) {
            // Basic number validation
            const numberRegex = /^[0-9]*$/;
            return numberRegex.test(number);
        }
    }

}
</script>