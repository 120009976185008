<template>
    <div class="card card-style preload-img"
        style="height: 130px;background-image: url('/images/pictures/18w.jpg');" data-card-height="130">
        <div class="card-center ms-3">
            <h1 class="color-white mb-0">Term of Use</h1>
        </div>
        <div class="card-center me-3">
            <router-link to="/"
                class="btn btn-m float-end rounded-xl shadow-xl text-uppercase font-800 bg-highlight">Back
                Home</router-link>
        </div>
        <div class="card-overlay bg-black opacity-80"></div>
    </div>

    <div class="card card-style">
        <div class="content mb-2 ">
            <h1 class="mb-0">Maklumat <span class="font-weight-light">yang </span>Dikumpul</h1>
            <p class="my-0">Pihak UiTM mungkin mengumpul maklumat berikut:
            <ul>
                <li>Nama dan Jawatan</li>
                <li>Maklumat untuk dihubungi termasuk alamat e-mel</li>
                <li>Maklumat demografik seperti poskod, keutamaan dan kesukaan</li>
                <li>Maklumat-maklumat lain yang berkaitan dengan kaji selidik pelanggan</li>
            </ul>
            </p>
        </div>

        <div class="content mb-2">
            <h1 class="mb-0">Apa yang <span class="font-weight-light">Dilakukan dengan Maklumat </span>yang Terkumpul
            </h1>
            <p class="my-0">Pihak UiTM memerlukan maklumat ini untuk memahami keperluan anda, seterusnya menyediakan
                perkhidmatan yang lebih baik untuk anda, dan terutamanya atas alasan-alasan berikut: </p>

            <ul>
                <li>penyimpanan rekod dalaman</li>
                <li>penambahbaikan perkhidmatan</li>
                <li>keselamatan </li>
            </ul>
            <p class="my-0">UiTM komited untuk memastikan maklumat anda selamat. Bagi mengelakkan capaian tanpa
                kebenaran atau pendedahan maklumat, pihak universiti telah menyediakan prosedur fizikal, elektronik dan
                pengurusan untuk melindungi maklumat yang dikumpulkan dalam talian</p>
        </div>

        <div class="content mb-2">
            <h1 class="mb-0">Mengawal <span class="font-weight-light">Maklumat </span>Peribadi</h1>
            <p class="my-0">Pihak universiti tidak akan menjual, mengedar atau menyewa maklumat peribadi anda kepada
                pihak ketiga melainkan setelah mendapat kebenaran atau dikehendaki oleh undang-undang untuk berbuat
                demikian. </p>
        </div>

        <div class="content mb-2">
            <h1 class="mb-0">Persetujuan <span class="font-weight-light">Anda </span></h1>
            <p class="my-0">Dengan memberikan data peribadi anda kepada Pusat Jaringan Alumni (ANC) UiTM, anda telah
                membaca dan menerima pernyataan-pernyataan ini serta telah bersetuju dengan pemprosesan data peribadi
                anda oleh ANC UiTM mengikut cara yang dinyatakan di dalam ini. Sekiranya anda memberikan data peribadi
                pihak ketiga kepada ANC UiTM , anda memberikan jaminan dan representasi kepada ANC UiTM bahawa anda
                telah mendapatkan persetujuan yang diperlukan daripada pihak ketiga tersebut untuk pemprosesan data
                peribadi yang berkaitan oleh ANC UiTM mengikut cara yang dinyatakan di dalam ini.</p>
        </div>


    </div>
</template>
<script>
export default {
  name: 'TermView',
}
</script>
