
<template>
  <div id="preloader" v-if="loading">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>
  <div class="card card-style">
    <div class="content mb-0">
      <h1 class="text-center font-800 font-40 mb-1 pt-2">Sign Up</h1>
      <p class="color-highlight text-center font-12">Please verify your details</p>
      <div v-if="error" class="alert alert-danger" role="alert">
        <div v-html="message"></div>
      </div>
      <div class="mb-3" v-if="!found">
        <label for="icno" class="form-label">IC/Passport/Student No.<span class="text-danger">*</span></label>
        <input type="text" class="form-control" :class="(formErrors.icno) ? 'is-invalid' : ''" id="icno"
          v-model="profile.icno" maxlength="12" :readonly="found">
        <div v-if="formErrors.icno" class="invalid-feedback">
          {{ formErrors.icno }}
        </div>
      </div>
      <a href="#" @click="verify" v-if="!found"
        class="btn btn-m mb-4 btn-full rounded-sm shadow-l bg-highlight text-uppercase font-900 mt-4"
        :disabled="searching">
        <i class="fa-solid fa-spinner fa-spin-pulse" v-if="searching"></i>
        Verify</a>
      <div v-if="found">
        <div class="mb-3">
          <label for="icno_view" class="col-form-label">IC/Passport/Student No.</label>
          <input type="text" readonly class="form-control-plaintext" id="icno_view" :value="profile.icno">
        </div>
        <div class="mb-3">
          <label for="nama" class="col-form-label">Nama</label>
          <input type="text" readonly class="form-control-plaintext" id="nama" :value="profile.nama">
        </div>
        <div class="mb-3">
          <label for="nama" class="col-form-label">Email</label>
          <input type="text" readonly class="form-control-plaintext" id="nama" :value="user.email">
        </div>
        <div class="mb-3">
          <label for="status_pekerjaan" class="form-label">Current Employement Status <span
              class="text-danger">*</span></label>
          <select class="form-select" id="status_pekerjaan" v-model="profile.status_pekerjaan"
            :class="(formErrors.status_pekerjaan) ? 'is-invalid' : ''">
            <option value="" disabled selected>Select a Value</option>
            <option value="Employed">Employed</option>
            <option value="Self-employed">Self-employed</option>
            <option value="Unemployed">Unemployed</option>
          </select>
          <div v-if="formErrors.status_pekerjaan" class="invalid-feedback">
            {{ formErrors.status_pekerjaan }}
          </div>
        </div>
        <div class="mb-3" v-if="profile.status_pekerjaan == 'Employed' || profile.status_pekerjaan == 'Self-employed'">
          <label for="sektor_pekerjaan" class="form-label">Employement Sector <span class="text-danger">*</span></label>
          <select class="form-select" id="sektor_pekerjaan" v-model="profile.sektor_pekerjaan"
            :class="(formErrors.sektor_pekerjaan) ? 'is-invalid' : ''">
            <option value="" disabled selected>Select a Value</option>
            <option value="Public Sector">Public Sector</option>
            <option value="Private Sector">Private Sector</option>
          </select>
          <div v-if="formErrors.sektor_pekerjaan" class="invalid-feedback">
            {{ formErrors.sektor_pekerjaan }}
          </div>
        </div>
        <div class="mb-3" v-if="profile.status_pekerjaan == 'Employed' || profile.status_pekerjaan == 'Self-employed'">
          <label for="bidang_pekerjaaan" class="form-label">Occupational Field <span
              class="text-danger">*</span></label>
          <select class="form-select" id="bidang_pekerjaaan" v-model="profile.bidang_pekerjaaan"
            :class="(formErrors.bidang_pekerjaaan) ? 'is-invalid' : ''">
            <option value="" disabled selected>Select a Value</option>
            <option value="Agriculture">Agriculture</option>
            <option value="Mining and quarrying">Mining and quarrying</option>
            <option value="Manufacturing">Manufacturing</option>
            <option value="Construction">Construction</option>
            <option value="Services">Services</option>
          </select>
          <div v-if="formErrors.bidang_pekerjaaan" class="invalid-feedback">
            {{ formErrors.bidang_pekerjaaan }}
          </div>
        </div>
        <div class="mb-3" v-if="profile.status_pekerjaan == 'Employed' || profile.status_pekerjaan == 'Self-employed'">
          <label for="tahap_pekerjaaan" class="form-label">Employment Level <span class="text-danger">*</span></label>
          <select class="form-select" id="tahap_pekerjaaan" v-model="profile.tahap_pekerjaaan"
            :class="(formErrors.tahap_pekerjaaan) ? 'is-invalid' : ''">
            <option value="" disabled selected>Select a Value</option>
            <option value="Skilled workers">Skilled workers (e.g managers/legislators, profesional and
              technicians)
            </option>
            <option value="Semi skilled workers">Semi skilled workers (e.g clerical, service and sales)
            </option>
            <option value="Low-skilled workers">Low-skilled workers (e.g farmer and fishery, craft and
              related trades,
              plant and machine operators and elementary occupation)</option>
          </select>
          <div v-if="formErrors.tahap_pekerjaaan" class="invalid-feedback">
            {{ formErrors.tahap_pekerjaaan }}
          </div>
        </div>
        <div class="mb-3" v-if="profile.status_pekerjaan == 'Employed'">
          <label for="jawatan" class="form-label">Designation/Post <span class="text-danger">*</span></label>
          <input type="text" class="form-control" id="jawatan" v-model="profile.jawatan"
            :class="(formErrors.jawatan) ? 'is-invalid' : ''">
          <div v-if="formErrors.jawatan" class="invalid-feedback">
            {{ formErrors.jawatan }}
          </div>
        </div>
        <div class="mb-3" v-if="profile.status_pekerjaan == 'Employed' || profile.status_pekerjaan == 'Self-employed'">
          <label for="organisasi" class="form-label">Name of Organisation <span class="text-danger">*</span></label>
          <input type="text" class="form-control" id="organisasi" v-model="profile.organisasi"
            :class="(formErrors.organisasi) ? 'is-invalid' : ''">
          <div v-if="formErrors.organisasi" class="invalid-feedback">
            {{ formErrors.organisasi }}
          </div>
        </div>
        <div class="mb-3" v-if="profile.status_pekerjaan == 'Employed' || profile.status_pekerjaan == 'Self-employed'">
          <label for="location" class="form-label">Location of Workplace <span class="text-danger">*</span></label>
          <input type="text" class="form-control" id="location" v-model="profile.location"
            :class="(formErrors.location) ? 'is-invalid' : ''">
          <div v-if="formErrors.location" class="invalid-feedback">
            {{ formErrors.location }}
          </div>
        </div>
        <div class="mb-3" v-if="profile.status_pekerjaan == 'Unemployed'">
          <label for="student" class="form-label">Are you currently a full time student? <span
              class="text-danger">*</span></label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="profile.student" id="yes" value="true"
              :class="(formErrors.student) ? 'is-invalid' : ''">
            <label class="form-check-label" for="yes">
              Yes
              <span class="text-danger">*</span></label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="profile.student" id="no" value="false"
              :class="(formErrors.student) ? 'is-invalid' : ''">
            <label class="form-check-label" for="no">
              No
              <span class="text-danger">*</span></label>
          </div>
          <div v-if="formErrors.student" class="invalid-feedback">
            {{ formErrors.student }}
          </div>
        </div>
        <div class="mb-3" v-if="profile.status_pekerjaan == 'Unemployed' && profile.student == 'true'">
          <label for=" university" class="form-label">University <span class="text-danger">*</span></label>
          <select class="form-select" id="university" v-model="profile.university"
            :class="(formErrors.university) ? 'is-invalid' : ''">
            <option value="" disabled selected>Select a Value</option>
            <option v-for="(university, index) in tempUniversities" :key="index" :value="university.id">
              {{ university.name }} ({{ university.code }})</option>
          </select>
          <div v-if="formErrors.university" class="invalid-feedback">
            {{ formErrors.university }}
          </div>
        </div>
        <div class="mb-3"
          v-if="profile.status_pekerjaan == 'Unemployed' && profile.student == 'true' && profile.university == '999'">
          <label for="tempat_pengajian" class="form-label">Name of institution <span
              class="text-danger">*</span></label>
          <input type="text" class="form-control" id="tempat_pengajian" v-model="profile.tempat_pengajian"
            :class="(formErrors.tempat_pengajian) ? 'is-invalid' : ''">
          <div v-if="formErrors.tempat_pengajian" class="invalid-feedback">
            {{ formErrors.tempat_pengajian }}
          </div>
        </div>
        <a href="#" @click="consent(true)"
          class="btn btn-m mb-4 btn-full rounded-sm shadow-l bg-green-dark text-uppercase font-900 mt-4">Create
          account</a>


      </div>
    </div>
  </div>
  <!-- End of Page Content-->

  <div id="menu-gdpr" class="menu menu-box-bottom menu-box-detached rounded-m" data-menu-effect="menu-over"
    data-menu-height="450" style="display: block;  height: 450px;border: 2px solid green; ">
    <div class="menu-title mt-n1">
      <h1>Personal Data Protection Notice </h1>
      <p class="color-highlight">PERSONAL DATA PROTECTION POLICY</p>
      <a href="#" @click="consent(false)" class="close-menu"><i class="fa fa-times"></i></a>
    </div>
    <div class="content mt-2">
      <div class="divider mb-3"></div>
      <div class="pt-1" style="max-height: 300px; overflow:scroll;">
        <p class="lh-sm text-justify my-2">Personal Data Protection Act 2010 (PDPA) applies to all processes
          involving personal data in the
          country.</p>
        <p class="lh-sm text-justify my-2">UNIVERSITI TEKNOLOGI MARA (UiTM) is committed to ensuring that your
          privacy is protected according to
          the
          Personal Data Protection Act 2010.
        </p>
        <p class="lh-sm text-justify my-2">UiTM is required to inform you of your rights regarding your
          personal data
          that is being processed, collected, and further processed as well as the purposes of the data
          processing for
          administration, education, research, or non-commercial use only.</p>

        <p class="lh-sm text-justify my-2">By submitting this form, I hereby agree that UiTM may collect,
          obtain, store, and process the
          Personal Data
          that
          UiTM provides in this form for registration processing and administration of UiTM's internal usage.
        </p>

        <p class="lh-sm text-justify my-2">I hereby give consent to UiTM to:-
        <ol type="a" class="lh-sm text-justify my-2">
          <li>store and process my Personal Data;</li>
          <li>disclose my Personal Data to UiTM’s internal department</li>
        </ol>
        </p>

        <p class="lh-sm text-justify my-2">In addition, my personal data may be transferred within UiTM only.
          For the purpose of updating or
          correcting
          such data, I at any time apply to UiTM to have access to my personal data which are stored by UiTM.
        </p>
        <p class="lh-sm text-justify my-2 pb-4">For avoidance of doubt, Personal Data includes all data defined under
          the Personal Data Protection
          Act 2010 and
          includes all data that has been disclosed to UiTM via this form.</p>
      </div>
      {{ alumni }}
      <div class="bottom-corner">
        <a href="#" @click="register"
          class="btn btn-m btn-full bg-success text-uppercase rounded-sm font-800 text-white"
          style="border-radius: 0 !important">I
          accept</a>
        <p class="text-center pt-2 mb-0 font-10">
          <a href="#" @click="consent(false)" class="text-center color-theme opacity-50 px-2 pb-2 mt-3">Cancel. I
            don't consent.</a>
        </p>
      </div>
    </div>
  </div>
</template>
<style scoped>
.terms {
  overflow-y: scroll;
}

.text-justify {
  text-align: justify;
  text-justify: inter-word;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
.bottom-corner {
  left: 0px;
    right: 0px;
    z-index: 98;
    background-color: rgba(249, 249, 249, 0.98);
    box-shadow: 0 -5px 10px 0 rgb(0 0 0 / 6%);
    bottom: 0;
    position: fixed;
    text-align: center;
}
</style>
<script>
import { db } from '@/firebase.js';
import { doc, setDoc, getDoc } from "firebase/firestore"
import { useAuthStore } from '@/stores'
export default {
  name: 'TermView',
  data () {
    return {
      user:{},
      profile: {
        tempat_pengajian: '',
        university: '',
        student: '',
        status_pekerjaan: '',
        jawatan: '',
        location: '',
        organisasi: '',
        gaji: '',
        phone_no: '',
        tahap_pekerjaaan: '',
        bidang_pekerjaaan: '',
        sektor_pekerjaan: '',
        icno: '',
      },
      loading: false,
      data: [],
      found: false,
      error: false,
      alumni: {},
      message: '',
      universites: [],
      tempUniversities: [],
      searching: false,
      formErrors: {}
    };
  },
  async created () {
    const authStore = useAuthStore()
    this.user = await authStore.getCurrentUser();
    if (this.user) {
      this.universites = await this.readData()
      this.tempUniversities = await this.readData()
      return true
    } else {
      this.$router.push("/login");
    }
    
    
    
    
  },
  methods: {
    consent (status) {
      if (status == true) {
        document.getElementsByClassName('menu')[0].classList.add('menu-active');
        document.getElementsByClassName('menu-hider')[0].classList.add('menu-active');
      } else {
        document.getElementsByClassName('menu')[0].classList.remove('menu-active');
        document.getElementsByClassName('menu-hider')[0].classList.remove('menu-active');
      }
    },
    async readData () {
      const universites = await getDoc(
        doc(db, "myalumni", "parameters")
      );
      if (universites.exists()) {
        let tempUniversities = universites.data().universities
        tempUniversities = tempUniversities.sort((a, b) => {
          let fa = a.name.toLowerCase(), fb = b.name.toLowerCase();
          if (fa < fb) {
            return -1
          }
          if (fa > fb) {
            return 1
          }
          return 0
        })
        return tempUniversities;
      } else {
        alert('Fail to load data')
      }
    },
    async register () {
      this.profile.registered= 1;
      this.profile.picture= this.user.photoURL;
      delete this.profile['icno']; 
      this.icno= ''
      const provider = this.user.providerData[0].providerId.substring(0, this.user.providerData[0].providerId.lastIndexOf("."));
      if (provider == 'google')
      this.profile.google= this.user.providerData[0].email
      else if (provider == 'facebook')
        this.profile.facebook = this.user.providerData[0].email
      else if (provider == 'twitter')
        this.profile.twitter = this.user.providerData[0].email
      else if (provider == 'microsoft')
        this.profile.microsoft = this.user.providerData[0].email
      if (this.validateForm()) {
        await setDoc(doc(db, 'alumni', `${this.profile.alumni_id}`), this.profile, { merge: true }).then(() => {
          const authStore = useAuthStore()
          authStore.login(this.profile)
        })
      } 
    },
    verify () {
      this.formErrors = {}; // Clear previous errors

      if (!this.profile.icno) {
        this.formErrors.icno = 'IC/Passport/Student No. is required.';
      }
      else{
        this.searching = true
        const config = {
          headers: { Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2ODQzMDY1MDYsInNjb3BlIjoiL2FsdW1uYWkiLCJzdWIiOiJhbHVtbmFpIiwicGVtb2hvbiI6Ik1PSEQgQVpSVUwgQU1JUiBCSU4gTVVIQU1BRCBUQUpVRElOIiwibm9wZWtlcmphIjoiMjYxODUyIn0.t20JEOelzQEfZtdKnFzjMjmJmjPiXbf7yofpI6irhdY` }
        };
        const icno = this.profile.icno;

        this.axios
          .get(
            "https://fastapi.uitm.edu.my/alumnai/semak?nokp=" +
            this.profile.icno, config
          )
          .then(async (response) => {
            //298726
            if(response.data.stat == true){
              this.alumni_id = response.data.alumni_id
              const docRef = doc(db, "alumni", `${response.data.alumni_id}`);
              const docSnap = await getDoc(docRef);

              if (docSnap.exists()) {
                console.log(docSnap.data())
                this.error = false
                this.found = true
                this.profile = docSnap.data();
                this.profile.icno = icno
                this.searching = false
              } else {
                this.error = true
                this.searching = false
                this.message = "Sorry, data not found. Please contact our administrator at alumniuitm@uitm.edu.my or <a href='https://wa.me/60355442780' target='_blank'><i class='fab fa-whatsapp'></i> Whatsapp</a>"
              }
              
            }else{
              this.error = true
              this.message = "Sorry, data not found. Please contact our administrator at alumniuitm@uitm.edu.my or <a href='https://wa.me/60355442780' target='_blank'><i class='fab fa-whatsapp'></i> Whatsapp</a>"

              this.searching = false
            }
          }).catch((error) => {
            this.loading = false;
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          });

      }
      
    },
    validateForm () {
      let isValid = true;
      this.formErrors = {}; // Clear previous errors


      if (!this.profile.status_pekerjaan) {
        this.formErrors.status_pekerjaan = 'Employment status is required.';
        isValid = false;
      }

      if ((this.profile.status_pekerjaan == 'Employed' || this.profile.status_pekerjaan == 'Self-employed') && !this.profile.sektor_pekerjaan) {
        this.formErrors.sektor_pekerjaan = 'Employement Sector is required.';
        isValid = false;
      }

      if ((this.profile.status_pekerjaan == 'Employed' || this.profile.status_pekerjaan == 'Self-employed') && !this.profile.bidang_pekerjaaan) {
        this.formErrors.bidang_pekerjaaan = 'Occupational Field is required.';
        isValid = false;
      }

      if ((this.profile.status_pekerjaan == 'Employed' || this.profile.status_pekerjaan == 'Self-employed') && !this.profile.tahap_pekerjaaan) {
        this.formErrors.tahap_pekerjaaan = 'Employment Level is required.';
        isValid = false;
      }

      if (this.profile.status_pekerjaan == 'Employed' && !this.profile.jawatan) {
        this.formErrors.jawatan = 'Designation/Post is required.';
        isValid = false;
      }

      if ((this.profile.status_pekerjaan == 'Employed' || this.profile.status_pekerjaan == 'Self-employed') && !this.profile.organisasi) {
        this.formErrors.organisasi = 'Name of Organisation is required.';
        isValid = false;
      }

      if ((this.profile.status_pekerjaan == 'Employed' || this.profile.status_pekerjaan == 'Self-employed') && !this.profile.location) {
        this.formErrors.location = 'Location of Workplace is required.';
        isValid = false;
      }

      if (this.profile.status_pekerjaan == 'Unemployed' && !this.profile.student) {
        this.formErrors.student = 'Full time student status is required.';
        isValid = false;
      }

      if (this.profile.status_pekerjaan == 'Unemployed' && this.profile.student == 'true' && !this.profile.university) {
        this.formErrors.university = 'University is required.';
        isValid = false;
      }

      if (this.profile.status_pekerjaan == 'Unemployed' && this.profile.student == 'true' && this.profile.university == '999' && !this.profile.tempat_pengajian) {
        this.formErrors.university = 'Name of institution is required.';
        isValid = false;
      }
      return isValid;
    },
    isValidEmail (email) {
      // Basic email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    isNumber (number) {
      // Basic number validation
      const numberRegex = /^[0-9]*$/;
      return numberRegex.test(number);
    }
  },
}
</script>
