<script setup>
import { useAuthStore } from '@/stores'

const authStore = useAuthStore()
</script>

<template>
  <div v-if="showForm" class="header header-fixed header-logo-center">
    <router-link to="/" class="header-title color-white"><img src="/images/preload-logo.png" width="110"></router-link>
    <a @click="$router.go(-1)" class="back-button header-icon header-icon-1"><i class="fas fa-arrow-left"></i></a>
    <a v-if="authStore.user" href="#" data-toggle-theme class="header-icon header-icon-4" @click="authStore.logout()"><i
        class="fa fas fa-right-from-bracket"></i></a>
  </div>
  <div v-if="showForm" id="footer-bar" class="footer-bar-1">
    <router-link to="/profile"><i class="fa fa-user"></i><span>Profile</span></router-link>
    <router-link to="/card"><i class="fas fa-address-card"></i><span>Card</span></router-link>
    <router-link to="/"><i class="fa fa-home"></i><span>Home</span></router-link>
    <router-link to="/benefits"><i class="fa fa-gift"></i><span>Benefits</span></router-link>
    <a href="https://www.facebook.com/alumniuitm" target="_blank"><i class="fa fa-users"></i><span>Social</span></a>
  </div>
</template>
<script>
export default {
  computed: {
    showForm () {
      const publicPages = ['login', 'PageNotFound', 'privacy', 'terms','inapp','register'];
      return !publicPages.includes(this.$route.name);
    }
  }
};
</script>
