<template>
    <div class="card card-style preload-img"
        style="height: 130px;background-image: url('/images/pictures/18w.jpg');" data-card-height="130">
        <div class="card-center ms-3">
            <h1 class="color-white mb-0">Privacy Policy</h1>
        </div>
        <div class="card-center me-3">
            <router-link to="/"
                class="btn btn-m float-end rounded-xl shadow-xl text-uppercase font-800 bg-highlight">Back
                Home</router-link>
        </div>
        <div class="card-overlay bg-black opacity-80"></div>
    </div>

    <div class="card card-style">
        <div class="content mb-2 ">
            <h1 class="mb-0">Dasar <span class="font-weight-light">Privasi</span></h1>
            <p class="my-0">Pautan kepada dasar privasi yang berikut mesti dimasukkan ke dalam semua laman web UiTM:
                Dasar privasi ini menetapkan cara Universiti Teknologi MARA (UiTM) menggunakan dan melindungi sebarang
                maklumat yang anda berikan kepada UiTM apabila anda menggunakan laman web ini. UiTM komited untuk
                memastikan privasi anda dilindungi. Jika pihak UiTM meminta anda menyediakan maklumat tertentu untuk
                membolehkan anda dikenal pasti semasa penggunaan laman web ini, maklumat anda terjamin selaras dengan
                kenyataan privasi ini. UiTM boleh mengubah dasar ini dari semasa ke semasa dengan mengemas kini laman
                web ini. Anda perlu menyemak laman web ini dari semasa ke semasa untuk memastikan anda berpuas hati
                dengan sebarang perubahan. Dasar ini berkuat kuasa mulai 1 JULAI 2016.</p>
        </div>

        <div class="content mb-2">
            <h1 class="mb-0">Notis <span class="font-weight-light">Penafian</span></h1>
            <p class="my-0">Maklumat yang terkandung di sini adalah untuk maklumat dan pendidikan semata-mata. UiTM
                tidak bertanggungjawab atas sebarang kesilapan atau kerosakan akibat penggunaan maklumat yang terkandung
                di dalamnya. Pelbagai usaha telah dibuat untuk memastikan ketepatan maklumat yang dikemukakan sebagai
                fakta. Walau bagaimanapun kesilapan mungkin wujud. Pengguna mesti menyemak fakta-fakta yang diberikan
            </p>
        </div>

        <div class="content mb-2">
            <h1 class="mb-0">Perlindungan <span class="font-weight-light">Data</span></h1>
            <p class="my-0">Teknologi terkini termasuk penyulitan data adalah digunakan untuk melindungi data yang
                dikemukakan dan pematuhan kepada standard keselamatan yang ketat adalah terpakai untuk menghalang
                capaian yang tidak dibenarkan. </p>
        </div>

        <div class="content mb-2">
            <h1 class="mb-0">Keselamatan <span class="font-weight-light">Storan</span></h1>
            <p class="my-0">Semua storan elektronik dan penghantaran data peribadi akan dilindungi dan disimpan dengan
                menggunakan teknologi keselamatan yang sesuai..</p>
        </div>
    </div>
</template>
<script>
export default {
  name: 'PrivacyView',
  
}
</script>
