<template>
    <div v-if="showForm" class="footer card card-style">
        <a href="#" class="footer-title"><img src="/images/preload-logo.png" width="110"></a>
        <!---<span class="color-highlight">myAlumni</span>--->
        <p class="footer-text"><span>Alumni Network Centre (ANC)</span><br><br>Industry, Community, & Alumni Network
            (ICAN)<br>Universiti Teknologi MARA (UiTM)<br>Tel : +603-5544 2825 / 2643<br>Fax : +603-5544 2821</p>
        <div class="text-center mb-3">
            <a href="https://www.facebook.com/alumniuitm/" target="_blank"
                class="icon icon-xs rounded-sm shadow-l me-1 bg-facebook"><i class="fab fa-facebook-f"></i></a>
            <a href="https://twitter.com/alumniuitm_ANC" target="_blank"
                class="icon icon-xs rounded-sm shadow-l me-1 bg-twitter"><i class="fab fa-twitter"></i></a>
            <a href="https://www.instagram.com/alumniuitm_anc/" target="_blank"
                class="icon icon-xs rounded-sm shadow-l me-1 bg-instagram"><i class="fab fa-instagram"></i></a>
            <a href="https://www.youtube.com/channel/UC5XQcJ9ENat5iNaDhUoZrlg" target="_blank"
                class="icon icon-xs rounded-sm me-1 shadow-l bg-red-dark"><i class="fab fa-youtube"></i></a>
            <a href="https://goo.gl/maps/ycGjAVPhsfGrir9Q8" target="_blank"
                class="icon icon-xs rounded-sm me-1 shadow-l bg-red-dark"><i
                    class="fa-solid fa-map-location-dot"></i></a>
            <a href="https://wa.me/60355442780" target="_blank"
                class="icon icon-xs rounded-sm me-1 shadow-l bg-green-dark"><i class="fab fa-whatsapp"></i></a>
        </div>
        <p class="footer-copyright">Copyright &copy; Alumni Network Centre (ANC) <span id="copyright-year">2023</span>.
            All Rights Reserved.</p>
        <p class="footer-links"><router-link to="/privacy" class="color-highlight">Privacy Policy</router-link> |
            <router-link to="/terms" class="color-highlight ">Term of Use</router-link> | <a href="#"
                class="back-to-top color-highlight"> Back to Top</a>
        </p>
        <div class="clear"></div>
    </div>
</template>
<script>
export default {
  computed: {
   showForm () {  
          const publicPages = ['login','convo'];
        return !publicPages.includes(this.$route.name);
   }
  }
};
</script>
